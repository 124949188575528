.card-select {
    position: relative;
    width: 100%;
    margin-bottom: 15px;

    &_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 2px solid #e7e7e8;
        cursor: pointer;

        @media (max-width: 767px) {
            padding: 8px 0 8px 12px;
            font-size: 12px;
            font-weight: 500;
            // border-bottom: 0;
        }

        .card-select_value {
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: #babcbf;

            &.selected {
                color: #222;
            }

            &.account-value {
                justify-content: flex-start;
                align-items: center;

                img, svg {
                    width: 22px;
                    height: auto;
                    margin-right: 15px;
                }

                span {
                    &:last-child {
                        margin-left: auto;
                    }
                }
            }
        }

        &.error {
            border-color: #bf3535;

            .card-select_value {
                color: #bf3535;
            }
        }
    }

    &_icon {
        margin-left: 15px;

        &.active {
            transform: rotate(180deg);
        }

        @media (max-width: 767px) {
            margin-left: 8px;
        }
    }

    &_dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 999;
        // max-height: 250px;
        background-color: #fff;
        box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.05);
        // overflow-y: scroll;

        // &::-webkit-scrollbar {
        //     display: none;
        //     width: 0;
        //     opacity: 0;
        // }

        @media (max-width: 767px) {
            padding-top: 8px;
            padding-left: 12px;
        }
    }

    &_tabs {
        display: flex;
        padding: 15px 15px 10px 15px;
        align-items: flex-end;

        @media (max-width: 767px) {
            position: relative;
            padding: 0 0 8px 0;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                z-index: -1;
                height: 2px;
                background-color: #e7e7e8;
            }
        }

        .card-select_tab {
            width: fit-content;
            padding-bottom: 3px;
            font-weight: 600;
            font-size: 14px;
            color: #222;
            text-transform: uppercase;
            border-bottom: 2px solid transparent;
            margin-right: 10px;
            cursor: pointer;

            &.active {
                border-color: #222;
            }

            @media (max-width: 767px) {
                padding-bottom: 8px;

                &.active {
                    border-color: #bf3535;
                }
            }
        }
    }

    &_list {
        padding: 0;
        margin: 0;
    }

    &_option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        @media (max-width: 767px) {
            padding: 8px 0;
            font-size: 12px;
        }

        @media (min-width: 768px) {
            &:hover {
                background-color: #f2f2f2;
            }
        }

        .card-select_name {
            display: flex;
            align-items: center;

            .card-select_star {
                width: 15px;
                margin-right: 10px;

                @media (max-width: 767px) {
                    margin-right: 8px;
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &.account-option {
            justify-content: flex-start;
            align-items: center;

            .card-select_star {
                width: fit-content;
            }

            img {
                width: 22px;
                height: auto;
                // margin-right: 15px;
            }

            div {
                &:nth-child(2) {
                    margin-left: auto;
                }
            }
        }
    }
}
