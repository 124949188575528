.car__card-mobile {
  border-radius: 0;
  @extend .car__card;

  margin-bottom: 0;

  @include l-sm {
    border-radius: 0.5px;
    width: 95%;
    margin-bottom: 4%;
    height: 243px;
  }
}

.car__card-description-mobile {
  height: 199px;
  padding: 15px 20px 15px 20px;
  @extend .car__card-description;

  flex-direction: column;
  display: flex;

  @include l-sm {
    padding: 8px;
    height: 83px;
  }
}

.car__card-taglist-mobile {
  margin-bottom: 15px;
  @extend .car__card-taglist;

  .tag {
    height: 20px;
  }

  @include l-sm {
    margin-bottom: 10px;
  }

  &--change-order {
    @include l-sm {
      order: 4;
      margin-bottom: 0px;
    }
  }
}

  .car__card-image-mobile {
    width: 100%;
    height: 240px;

    &--image-does-not-exists-wrapper {
      height: 240px;
    }

    @extend .car__card-image;

    @include l-sm {
      height: 160px;

      &--image-does-not-exists-wrapper {
        height: 160px;
      }
    }

    img {
      @include l-sm {
        border-top-right-radius: 0.5px;
        border-top-left-radius: 0.5px;
      }
    }
  }

.car__card-title-mobile {
  font-size: 16px !important;
  overflow: hidden;
  position: relative;
  line-height: 19.36px;
  letter-spacing: 0.01em;

  @extend .car__card-title;

  // margin-bottom: 5px;

  @include l-sm {
    line-height: 15px;
    font-size: 12px;
    margin-bottom: 6px;
    font-size: 12px !important;
  }
}

.car__card-title-mobile::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 40%; /* 1 */
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* 2 */
}

.car__card-regnum-mobile {
  font-size: 12px;
  font-weight: 400;
  @extend .car__card-regnum;

  margin-bottom: 1px;

  @include l-sm {
    line-height: 15px;
    margin-bottom: 6px;
    font-size: 10px;
  }
}

.car__card-devider-mobile {
  display: none;
  @extend .car__card-devider;
  margin-bottom: 10px;

  @include l-sm {
    display: block;
    margin-bottom: 6px;
  }
}

.car__card-payment-mobile {
  font-size: 12px;
  font-weight: 500;
  @extend .car__card-payment;

  margin-bottom: 5px;

  @include l-sm {
    line-height: 10px;
    font-size: 8px;
    margin-bottom: 4px;
  }

  &-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    @extend .car__card-payment-value;

    @include l-sm {
      line-height: 10px;
      font-size: 8px;
    }
  }
}
.car__card-price-mobile {
  font-size: 12px;
  font-weight: 400;
  @extend .car__card-price;

  margin-bottom: 15px;

  @include l-sm {
    line-height: 10px;
    font-size: 8px;
    color: currentColor;
    margin-bottom: 8px;
  }

  &--without-mg {
    @include l-sm {
      margin-bottom: 0;
    }
  }
}

.car__card-preorder-mobile {
  button {
    max-width: 141px;
    max-height: 31px;
    font-size: 12px !important;
    padding: 7px 7px !important;
  }
  @include l-sm {
    display: none;
  }
}
