.personal-account-payments {
  &_add-btn {
    width: 40px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid var(--Color, #bf3535);
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }

  &_scroller {
    @include l-md {
      bottom: 100px;
    }
  }

  &_tab {
    gap: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    & > button {
      font-size: 14px !important;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.14px;
      text-transform: uppercase;
    }

    &-content_divider {
      height: 2px;
      background: var(--BG, #f2f3f6);
      margin: 16px 0;
    }
  }

  &_mobile {
    &-add-card {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 20;
      width: 100%;
      background: var(--default-white, #fff);
      box-shadow: 0px -5px 32px 0px rgba(0, 0, 0, 0.05);
      padding: 12px 16px 24px 12px;

      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr;

      & > button {
        width: 100%;
        color: var(--Text, #222);
        font-size: 12px;
        font-weight: 600;
        line-height: 140%; /* 16.8px */
        letter-spacing: 0.24px;
        text-transform: uppercase;
      }
    }
  }

  &_bank-card {
    border-radius: 4px;
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border: 2px solid transparent;
    padding: 15px;
    max-width: 215px;
    width: 100%;

    &.main {
      border: 2px solid var(--Grey-2, #babcbf);

      @include l-md {
        border: 1px solid var(--Color, #bf3535);
      }
    }

    @include l-xxl {
      max-width: 100%;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 20px;

      & > h1 {
        color: var(--Text, #222);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 8px 0 0;
      }
    }

    &_number {
      color: #606569;
      font-size: 14px;
      font-weight: 500;
      line-height: initial;
      margin: 0;
    }

    &_button {
      background-color: transparent;
      border: none;

      &-like.active {
        & > svg path {
          fill: #bf3535;
        }
      }

      // &-edit > svg {
      //   path {
      //     // fill: #222;
      //   }
      // }
    }
  }

  &_bank-card-list {
    display: flex;
    align-items: start;
    padding: 30px 0 0 0;
    border-top: 2px solid #f2f3f6;
    margin: 20px 0 0 0;

    @include l-md {
      padding: 0;
      border-top: none;
      margin: 0;
    }

    &_cards {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      width: 100%;
      margin: 0 0 0 10px;

      @include l-xxl {
        grid-template-columns: repeat(4, 1fr);
      }

      @include l-xl {
        grid-template-columns: repeat(3, 1fr);
      }

      @include l-md {
        grid-template-columns: 1fr;
        gap: 16px;
        margin: 0;
      }
    }
  }

  &_score-card {
    border-radius: 4px;
    border: 2px solid transparent;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 15px;
    max-width: 272px;
    width: 100%;

    &.main {
      border: 2px solid var(--Grey-2, #babcbf);

      @include l-md {
        border: 1px solid var(--Color, #bf3535);
      }
    }
    @include l-xxl {
      max-width: 100%;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 20px;

      & > h1 {
        color: var(--Text, #222);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }
    }

    &_info {
      padding: 0;
      margin: 0;
      & > li {
        color: #606569;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;

        &:not(:last-child) {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  &_score-card-list {
    display: flex;
    align-items: start;
    padding: 30px 0 0 0;
    border-top: 2px solid #f2f3f6;
    margin: 20px 0 0 0;

    @include l-md {
      padding: 0;
      border-top: none;
      margin: 0;
    }

    .personal-account-payments_add-btn {
      height: 220px;
    }

    &_cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      width: 100%;
      margin: 0 0 0 10px;
      @include l-xxl {
        grid-template-columns: repeat(3, 1fr);
      }
      @include l-xl {
        grid-template-columns: repeat(2, 1fr);
      }
      @include l-md {
        grid-template-columns: 1fr;
        gap: 16px;
        margin: 0;
      }
    }
  }
}
