.rent-paginator-arrow {
  border-color: transparent;
  border: none;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
