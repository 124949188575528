.personal-account_calendar {
  position: fixed;
  z-index: 20;
  left: 66px;
  bottom: 16px;
  border-radius: 8px;
  border: 1px solid var(--BG, #f2f3f6);
  background: var(--default-white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
