.backButton {
  font-family: "Inter", "sans-serif";
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-transform: uppercase;

  color: #606569;
  border: none;
  background: none;
}
