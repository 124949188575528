@import './../../../scss/mix';

.mobile-modal {
  height: 100hw;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  overflow-x: scroll;
  display: none;

  &.active {
    display: flex;
    flex-direction: column;
  }

  &_header {
    width: 100%;
    height: 264px;
    min-height: 264px;
    background-image: url("../../../images/common/modal-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -20px 0 0 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      & > button {
        border: none;
        background-color: transparent;
      }
    }

    &-bottom {
      & > h3 {
        color: var(--default-white, #fff);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
        text-transform: uppercase;
        margin: 0 0 8px 0;
      }

      & > p {
        color: var(--default-white, #fff);
        font-size: 10px;
        font-weight: 600;
        line-height: 140%; /* 14px */
        letter-spacing: 0.1px;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 264px);
    padding: 24px 16px 0 16px;
    background-color: #fff;

    h1 {
      color: var(--Text, #222);
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      margin: 0 0 8px 0;
      line-height: 24px;

      & > span {
        font-size: 16px;
      }
    }

    p {
      color: var(--Grey-1, #606569);
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Inter;
      font-size: 12px;

      font-weight: 500;
      line-height: 140%; /* 16.8px */
      margin: 0 0 16px 0;
    }

    & > form {
      margin: 0 0 40px 0;
    }

    &-input {
      &:not(:last-child) {
        margin: 0 0 12px 0;
      }
    }

    &-action {
      margin-top: auto;

      span {
        font-size: 8px;
      }
    }

    &-company {
      text-align: left;
      padding: 0 0 16px 0;
    }

    &-auth {
      height: 100%;
      display: flex;
      flex-direction: column;

      .call-content-input {
        padding-top: 51px;
      }
    }

    &-ordercall {
      display: flex;
      flex-direction: column;
      height: 120%;
    }

    &-callrequest {
      @extend .mobile-modal_body-ordercall;
    }

    .file-input_icon {
      display: block;
      width: 20px;
      height: auto;
    }
  }
}

.codeInput {

  .styles_react-code-input__CRulA {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    border-radius: 0;
    input{
      caret-color: black;
      color: black;
      &:focus {
        border: none;
        border-bottom: 2px solid black;
      }
      max-width: 50px;
      border: none;
      border-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 2px solid #e7e7e8;
      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:last-child {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      @include l-sm {
        width: 50px;
      }
    }
    @include l-sm {
      width: 90vw;
    }
  }
}