@import "./../../../../scss/mix";

.request-button {
  width: 190px;
  height: 49px;
  background: transparent;
  border: 2px white solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: 7vh;
  transition: all 0.2s ease;
  &:hover {
    background: white;
    color: black;
    path {
      stroke: black;
    }
  }

  svg {
    margin-left: 5%;
  }

  @include l-md {
    width: 140px;
    height: 39px;
    font-size: 12px;
  }
}

.invite_text {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 22vh;
  @include l-md {
    font-size: 16px;
    font-weight: 600;
    margin-top: 7vh;
  }
  .lighter {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @include l-md {
        font-size: 12px;
        font-weight: 400;
      }
  }
}
.invite_lighter {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @include l-md {
        font-size: 12px;
        font-weight: 400;
      }
  }