.personal-account_actions {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    &-item {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 1024px) {
            &:hover {
                .personal-account_actions-btn {
                    background-color: #222;
                    border-color: #222;

                    svg {
                        path {
                            stroke: #fff;
                        }
                    }
                } 

                span {
                    color: #222;
                    opacity: 1;
                }
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;

            svg {
                opacity: 0.5;
            }

            span {
                opacity: 1;
            }
        }
    }

    &-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        outline: none;
        border-radius: 2px;
        border: 2px solid #F2F3F6;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        transition: .2s;
        padding: 10px;
        margin-bottom: 5px;
    }

    span {
        color: #737D89;
        opacity: 0.5;
        transition: .2s;
    }
}
