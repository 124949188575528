@import "../../../scss/mix";

.carImageDoesNotExists {
  height: 240px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #f2f3f6;
  color: #babcbf;
  opacity: 0.5;

  &Img {
    width: 40px;
    height: 40px;
  }

  &Text {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.6px;
    text-align: center;
    text-decoration: none;
  }

  @include l-sm {
    height: 100%;
    gap: 8px;
    &Img {
      width: 24px;
      height: 24px;
    }

    &Text {
      font-weight: 600;
      line-height: 12px;
      font-family: Inter;
      font-size: 10px;
    }
  }

  &Responsible {
    @include l-sm {
      height: 100%;

      &Img {
        width: 30px;
        height: 30px;
      }

      &Text {
        font-size: 14px;
      }
    }
  }
}
