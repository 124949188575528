.personal-account_form {
  width: 450px;

  &-topic > h3 {
    color: var(--Text, #222);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin: 40px 0 20px 0;

    &:first-child {
      margin: 0 0 20px 0;
    }
  }

  &-topic {
    &:not(:first-child) {
      margin: 40px 0 0 0;
    }

    & > input:not(:last-child) {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  input[type="date"] {
    background-color: transparent;
  }

  .site-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      svg {
        path {
          stroke: white;
        }
      }
    }

    svg {
      width: 18px;
      height: auto;
      margin-right: 10px;
    }
  }

  &-item {
    position: relative;

    input {
      margin-bottom: 15px;
    }

    .personal-account_form-edit {
      position: absolute;
      z-index: 2;
      right: 15px;
      top: 10px;
      width: 22px;
      background-color: transparent;
      border: 0;
      outline: none;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &-mobActions {
    display: flex;
    flex-direction: column;
    margin: -6px -6px 34px -6px;
    width: 100%;

    button {
      margin: 6px;
    }
  }

  &-date {
    position: relative;
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border-bottom: 2px solid #e7e7e8;
    color: #babcbf;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 12px;
      padding: 8px 12px;
    }

    &.active {
      color: #222;
      border-color: #222;

      svg {
        path {
          stroke: #222;
        }
      }
    }

    &_value {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::after {
        content: '';
        position: absolute;
        top: 0; 
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    svg {
      path {
        stroke: #babcbf;
      }
    }

    .rdrCalendarWrapper {
      position: absolute;
      top: calc(100% + 16px);
      left: 0;
      z-index: 61;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    }
    
  }
}

@media (max-width: 1024px) {
  .personal-account_form {
    width: 100%;

    &-topic > h3 {
      margin: 40px 0 8px 0;
      font-size: 14px;

      &:first-child {
        margin: 40px 0 8px 0;
      }
    }
    &-topic {
      margin: 40px 0 0 0;

      & > input:not(:last-child) {
        margin: 0 0 12px 0;
      }
    }
  }
}
