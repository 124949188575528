.quick-rent {
  width: 100%;
  display: grid;
  padding: 24px 16px 16px 16px;
  grid-template-columns: 3px 1fr;
  column-gap: 16px;
  box-shadow: 0px 0px 24px 0px #00000014;
  box-sizing: border-box;
  &_line {
    height: 100%;
    min-width: 3px;
    background-color: #bf3535;
  }
  &_content {
    width: 100%;
    box-sizing: border-box;
    font-size: 10px;
    font-weight: 400;
    height: 83px;
    p {
      margin-bottom: 8px;
      line-height: 14px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 22.4px !important;
    }
  }
}
