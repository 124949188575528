.personal-account-rent-car {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  border-top: 2px solid #f2f3f6;
  padding: 25px 0 0 0;

  @include l-xl {
    grid-template-columns: 1fr;
  }

  &_card {
    border-radius: 4px;
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    width: 780px;

    display: flex;
    align-items: center;
    padding: 30px 40px;

    @include l-xxl {
      width: 700px;
    }

    @include l-xl {
      width: 100%;
    }

    &_image {
      max-width: 334px;
      margin: 0 60px 0 0;

      @include l-xxl {
        max-width: 300px;
        margin: 0 40px 0 0;
      }

      @include l-xl {
        max-width: 350px;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_date {
      color: var(--Grey-1, #606569);
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.14px;
      margin: 0 0 34px 0;
    }

    &_brand {
      font-family: Inter;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      margin: 0 0 10px 0;

      &-model {
        color: #bf3535;
      }
    }

    &_number {
      color: var(--Grey-1, #606569);
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.18px;
      text-transform: uppercase;
      margin: 0 0 34px 0;
    }

    &_price {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
      margin: 0 0 15px 0;

      & > p {
        margin: 0 0 5px 0;
      }

      & > span {
        font-size: 22px;
        font-weight: 600;
      }
    }

    &_deposit {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;

      &-value {
        font-weight: 600;
      }
    }
  }

  &_payment {
    @include l-xl {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
    &-card {
      border-radius: 4px;
      background: var(--default-white, #fff);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 20px;
      min-height: 158px;
      display: flex;
      align-items: center;
      @include l-xl {
        width: 49%;
        padding: 20px 40px;
      }

      &_header {
        display: flex;
        flex-direction: column;
        &-confirmed {
          & > h4 {
            color: var(--Grey-1, #606569);
            font-size: 24px;
            font-weight: 600;
          }
          & > p {
            color: var(--Grey-1, #606569);
            font-size: 16px;
            font-weight: 500;
            margin: 0;
          }
        }
      }

      &_price {
        font-size: 24px;
        font-weight: 600;
        margin: 0 0 15px 0;
      }

      &_btn {
        padding: 10px 25px;
      }

      &_date {
        color: var(--Grey-1, #606569);
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 5px 0;
      }
    }

    &-status {
      border-radius: 4px;
      background: var(--default-white, #fff);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 25px 20px;
      margin: 15px 0 0 0;

      @include l-xl {
        margin: 0;
        padding: 20px 40px;
        width: 49%;
        display: flex;
        align-items: center;
      }

      &-success {
        display: flex;
        align-items: start;
        &_icon {
          margin: 0 15px 0 0;
        }

        &_name {
          font-size: 24px;
          font-weight: 600;
          margin: 0 0 15px 0;
        }

        &_date {
          color: var(--Grey-1, #606569);
          font-size: 16px;
          font-weight: 500;
          margin: 0;

          &:last-child {
            margin: 10px 0 0 0;
          }
        }
      }

      &-waiting {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        &_title {
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 10px 0;
        }
        &_price {
          font-size: 24px;
          font-weight: 600;
          margin: 0 0 15px 0;
        }
        &_date {
          color: #606569;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
        }
      }

      &-confirmed {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        &_header {
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 5px 0;
        }
        &_date {
          color: #606569;
          font-size: 16px;
          font-weight: 500;
        }

        &_btn {
          padding: 10px 25px;
        }
      }

      &-payed {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        &_header {
          color: #606569;
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 5px 0;
        }
        &_date {
          color: #606569;
          font-size: 16px;
          font-weight: 500;
        }

        &_btn {
          padding: 10px 25px;
          background: linear-gradient(85deg, #babcbf -0.11%, rgba(186, 188, 191, 0.5) 99.89%);
          border: none;
          color: #fff;
          &:hover {
            background: linear-gradient(85deg, #babcbf -0.11%, rgba(186, 188, 191, 0.5) 99.89%);
          }
        }
      }
    }
  }

  &_mobile {
    border-radius: 4px;
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &-info {
      &_image {
        height: 200px;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &_body {
        padding: 16px;

        &-date {
          color: #606569;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.12px;
          margin: 0 0 16px 0;
        }

        &-carname {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.16px;
          text-transform: uppercase;
          margin: 0 0 4px 0;
          & > span {
            color: #bf3535;
          }
        }
        &-seria {
          color: #606569;
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.14px;
          text-transform: uppercase;
          margin: 0 0 16px 0;
        }
        &-price {
          color: #222;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.12px;
          margin: 0 0 8px 0;

          & > p > span {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.2px;
            margin: 4px 0 0 0;
          }
        }

        &-payment-date,
        &-deposit {
          color: #222;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.12px;
          margin: 0 0 8px 0;
          & > span {
            font-weight: 600;
          }
        }
        &-deposit {
          margin: 0;
        }
      }
    }

    &-check {
      padding: 0 16px 16px 16px;
      &_btn {
        width: 100%;
        padding: 12px 16px;
      }

      &_status {
        &-success {
          & > .personal-account-rent-car_mobile-check_result {
            border: 1.5px solid #008f4b;
          }
        }
        &-waiting {
          & > .personal-account-rent-car_mobile-check_result {
            border: 1.5px solid var(--1, #E19F52);
          }
        }
        &-confirmed {
          & > .personal-account-rent-car_mobile-check_result {
            border: 1.5px solid #babcbf;
            margin: 0 0 16px 0;

            h2,
            h4 {
              color: #606569 !important;
            }
          }

          & > .personal-account-rent-car_mobile-check_btn {
            border-radius: 2px;
            background: linear-gradient(85deg, #babcbf -0.11%, rgba(186, 188, 191, 0.5) 99.89%);
            box-shadow: 0px 0px 7.882px 0px rgba(0, 0, 0, 0.1);
            border: none;
            color: #fff;
          }
        }

        &-payed {
          & > .personal-account-rent-car_mobile-check_result {
            border: 1.5px solid #008f4b;
          }
        }
      }

      &_result {
        display: flex;
        align-items: center;
        border-radius: 2px;
        box-shadow: 0px 0px 7.88211px 0px rgba(0, 0, 0, 0.1);
        padding: 12px;
        &-image {
          width: 28px;
          height: 28px;
          margin: 0 12px 0 0;
          & > img {
            width: 100%;
            height: 100%;
          }
        }

        &-data {
          & > h2 {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.28px;
            text-transform: uppercase;
            margin: 0 0 4px 0;
          }
          & > h4 {
            font-size: 10px;
            font-weight: 500;
            margin: 0 0 4px 0;

            & > span {
              font-weight: 600;
            }
          }

          & > p {
            color: var(--Text, #222);
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
          }
        }
      }
    }

    &-payment {
      margin: 0;
    }
  }
}
