.link {
    text-decoration: none;
}

.button {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    display: block;
    width: -moz-fit-content;
    outline: none;
    background: #312072;
    // border: 1.5px solid #BF3535;
    border-radius: 20px;
    color: white;
    line-height: 140%;
    transition: all 0.15s ease-in-out;
    padding: 14px 19px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.logo {
    width: 40px;
}

.logo-text {
    font-size: 18px;
    button {
        padding: 5px 15px;
    }
}

.logo-block {
    display: flex;
    align-items: center;
    gap: 4px;
}