$textColor: #222222;
$selectedColor: #bf3535;
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  max-width: 1920px;
  // position-fixed top-0 start-0 w-100 z-100
  @media (min-width: 1920px) {
    margin-left: calc((100vw - 1920px) / 2);
  }
}

.header-links {
  display: flex;
  column-gap: 60px;
  align-items: center;
  width: 100%;
  @include media-breakpoint-down(xl) {
    column-gap: 40px;
  }
}

.header-padding {
  padding-top: 22px;
  padding-bottom: 21px;
  @include l-sm {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.header-link {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  position: relative;
  /* identical to box height, or 20px */
  font-size: 14px;
  text-wrap: nowrap;
  letter-spacing: 0.02em;
  //text-transform: uppercase;

  transition: all 0.2s ease-in-out;

  @include media-breakpoint-down(xl) {
    padding: 8px 10px;
  }
  /* Text */
  &:after {
    //content: '';
    display: block;
    width: 0;
    height: 3px;
    border-radius: 25px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
    background: $textColor;
  }

  color: $textColor;
  text-decoration: none;

  &:hover {
    //color: $textColor;
    color: $selectedColor;

    &:after {
      width: 50%;
    }
  }

  &.selected {
    color: $selectedColor;

    &:hover {
      color: $selectedColor;
    }

    &:after {
      background: $selectedColor;
    }
  }

  &.light {
    color: white;

    &:hover {
      color: $selectedColor;
    }

    &:after {
      background: $selectedColor;
    }
  }
}

.header-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_phone,
  &_burger {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &_phone_dark,
  &_burger_dark {
    color: #000000;
  }

  &_burger_light,
  &_phone_light {
    color: #ffffff;
  }

  @include lg {
    display: none;
  }
}

.header-desktop {
  width: 100%;
  display: flex;
  gap: 130px;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.header-controls {
  column-gap: 20px;
  align-items: center;
  display: none;
  @include lg {
    display: flex;
  }
}

.fav-btn {
  cursor: pointer;
  position: relative;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e7e7e8;
  filter: drop-shadow(0px 0px 10px rgba(55, 73, 96, 0.25));
  border-radius: 25px;
  outline: none;
  background-size: 22px 22px;
  color: $textColor;
  background: transparent;
  transition: all 0.2s ease-in-out;
  background-image: url("./../../images/common/favB.svg");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;

  &:hover {
    border-color: $selectedColor;
    background-color: $selectedColor;
    background-image: url("./../../images/common/favW.svg");

    .fav-tooltip {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.light {
    color: #e7e7e8;
    background-image: url("./../../images/common/favW.svg");

    &:hover {
      //color: white;
      border-color: $selectedColor;
      background-color: $selectedColor;
      background-image: url("./../../images/common/favW.svg");
      color: $textColor;
    }
  }
}

.user-btn {
  position: relative;
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #e7e7e8;
  filter: drop-shadow(0px 0px 10px rgba(55, 73, 96, 0.25));
  border-radius: 25px;
  outline: none;
  background-size: 22px 22px;
  color: $textColor;
  background: transparent;
  transition: all 0.2s ease-in-out;
  background-image: url("./../../images/common/userB.svg");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  cursor: pointer;

  .user-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #BF3535;
    color: white;
    font-weight: 600;
    font-size: 10px;
    width: fit-content;
    height: 16px;
    padding: 0 5px 0 5px;
    border-radius: 12px;
    bottom: -2px;
    left: 22px;
  }

  &:hover {
    border-color: $textColor;
    background: black;
    background-image: url("./../../images/common/userW.svg");
    background-size: 18px 18px;
    background-position: center;
    background-repeat: no-repeat;

    .user-tooltip {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.light {
    color: white;
    border: 1.5px solid white;
    background-image: url("./../../images/common/userW.svg");

    &:hover {
      //color: white;
      border-color: white;
      background-color: white;
      background-image: url("./../../images/common/userB.svg");
      color: $textColor;
    }
  }
}

.user-tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 2;
  padding-top: 10px;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;

  &.authentificated {
    left: 0;
    transform: none;
    min-width: 282px;
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    font-weight: 500;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    border-radius: 2px;

    a {
      text-decoration: none;
      color: #222;
    }

    span {
      display: block;
      text-decoration: none;
      color: #222;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    button {
      position: relative;
      z-index: 3;
      display: flex;
      background-color: transparent;
      border: 0;
      align-items: center;

      img,
      svg {
        margin-right: 10px;
        pointer-events: none;
        max-width: 16px;
        height: auto;
      }

      &:hover {
        color: $selectedColor;

        svg {
          path {
            stroke: $selectedColor;
          }
        }
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  z-index: 199;
  transform: scale(0);
  transition: all 8ms ease-in-out;

  &.active {
    transform: scale(1);
    backdrop-filter: blur(5px);
  }

  &_container {
    width: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: -100%;
    top: 0;
    z-index: 200;
    background-color: #000000;
    padding: 30px 16px;
    transition: all 0.5s ease-in-out;

    &.active {
      left: 0;
    }

    @include media-breakpoint-down(md) {
      width: 280px;
    }
  }

  &_routes {
    padding: 0;
    margin: 52px 0 0 0;

    &_item {
      list-style: none;

      &:not(:last-child) {
        margin: 0 0 24px 0;
      }

      & > a {
        text-decoration: none;
        display: flex;
        align-items: center;

        & > p {
          margin: 0 0 0 12px;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }
  }

  &_login {
    display: flex;
    cursor: pointer;

    & > h5 {
      margin: 0 0 0 10px;
      font-weight: 500;
      color: #ffffff;
      font-size: 16px;
    }

    & > img {
      width: 20px;
      height: 20px;
    }
    @include l-sm {
      margin-top: 16px;
    }
  }

  &_advance {
    display: flex;
    align-items: start;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px;
    border-radius: 4px;
    transform: scale(0);
    transition: all 0.1s ease-in;
    position: relative;
      &.active {
        transform: scale(1);
        background-color: rgba(0, 0, 0, 0.5) !important;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      }

    &_content {
      width: 205px;
      & > h5,
      p {
        color: #ffffff;
        font-weight: 400;
      }

      & > h5 {
        font-size: 12px;
        margin: 0 0 4px 0;
      }

      & > p {
        font-size: 10px;
        margin: 0;
      }
    }

    &_close {
      position: absolute;
      top: 6px;
      right: 12px;
      width: 16px !important;
      height: 16px !important;
      // transform: translateY(-6px);

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &_user {
    color: white;

    a {
      color: white;
      text-decoration: none;
    }

    span {
      &:nth-child(2) {
        color: #606569;
      }
    }

    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      outline: none;
      border: 0;
      color: white;

      svg {
        width: 20px;
        height: auto;
        margin-right: 16px;

        path {
          stroke: white;
        }
      }
    }
  }

  //opacity: 0.75;
  //filter:blur(20px);
  //-o-filter:blur(20px);
  //-ms-filter:blur(20px);
  //-moz-filter:blur(20px);
  //-webkit-filter:blur(20px);
}

.mobile-menu__white {
  &_container {
    background-color: #fff;
  }

  &_routes {
    &_item {
      & > a {
        & > p {
          color: #222222;
        }
      }
    }
  }

  &_login {
    & > h5 {
      color: #222;
    }
  }

  &_user {
    color: #222;

    a {
      color: #222;
    }

    button {
      color: #222;

      svg {
        path {
          stroke: #bf3535;
        }
      }
    }
  }
}
