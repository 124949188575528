.car__card {
  width: 100%;
  height: 439px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  // border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  color: #222;
}

.car__card--car-does-not-exists-wrapper {
  height: 255px;
}

.car__card-description {
  padding: 20px 28px 25px 28px;
}

.car__card-taglist {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
  line-height: 17px;

  @include l-lg {
    gap: 7px;
  }
}
.car__card-image {
  width: 100%;
  height: 255px;

  img {
    width: 100%;
    height: 100%;
    // border-top-left-radius: 4px;
    // border-top-right-radius: 4px;
    object-fit: cover;
  }

}
.car__card-title {
  font-family: "Inter";
  line-height: 29px;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: $textColor;
  text-decoration: none;
  cursor: pointer;
  .model {
    color: $redColor;
  }
  @include l-lg {
    font-size: 22px;
    margin-bottom: 5px;
  }

  @include l-sm {
    margin-bottom: 5px;
  }
}

.car__card-regnum {
  color: #606569;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 15px;
}

.car__card-devider {
  height: 1px;
  background: #F2F3F6;
  margin-bottom: 15px;
}


.car__card-payment {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  &-value {
    line-height: 24px;
    font-weight: 600;
    font-size: 20px;
    @include l-sm {
      font-size: 18px;
    }
  }
}
.car__card-price {
  font-family: "Inter";
  font-weight: 500;
  line-height: 17px;
  color: #606569;
  font-size: 14px;
  margin-bottom: 40px;

  &-value {
  }
}