.footer__map {
  position: relative;
  z-index: 20;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 580px;
  .map_image {
    object-fit: cover;
  }

  &:after {
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 30;
    position: absolute;
    content: "";
  }
  .map_container {
    position: relative;
    display: flex;
    filter: grayscale(1);
    min-height: 400px;
    height: 100%;
    width: 100%;

    // canvas {
    //   // filter: grayscale(0.7) !important;
    //   // -webkit-filter: grayscale(100%) !important;
    // }
  }
}

// .ymaps-layers-pane {
//   filter: grayscale(1);
//   -ms-filter: grayscale(1);
//   -webkit-filter: grayscale(1);
//   -moz-filter: grayscale(1);
//   -o-filter: grayscale(1);
// }

.map_image-container {
  position: absolute;
  cursor: pointer;
  z-index: 1200;
  img {
    width: 40px;
    height: 40px;
  }
}

.map_image {
  transform: translate(-50%, -100%);
  position: absolute;
  @include l-sm {
    width: 24px;
  }
  z-index: 1200;
}

.footer {
  background-size: cover;
  background-image: url("../../images/common/footer/footer_bg.webp");
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @include l-md {
    background: none;
  }

  &__contacts-container {
    @include l-md {
      background-image: url("../../images/index/logo-bg-no-car.webp");
      background-position: left 24% top 0;
      background-size: cover;
      & > div {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  //@include media-breakpoint-down(md) {
  //  background-image: none !important;
  //}
}

.contacts-page {
  color: #222222;
  padding-top: 60px;
  position: relative;
  @include l-xl {
    padding-top: 40px;
  }
  .cited-mobile_divider {
    margin-bottom: 40px;
  }
  @include l-sm {
    padding: 24px 0 0 0;
  }
  &_top {
    font-family: "Inter";
    font-weight: 500;
    font-size: 60px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 120%;
    padding-bottom: 15px;
    border-bottom: 6px solid $redColor;
    margin-bottom: 45px;
    max-width: 460px;
    .cited-header-without-m {
      font-weight: 500;
    }
    @include l-xl {
      margin-bottom: 30px;
    }
    @include l-md {
      padding-bottom: 20px;
      margin-bottom: 30px;
      font-size: 42px;
    }

    @include l-sm {
      font-size: 24px;
      padding: 0 0 16px 0;
      border-bottom: none;
      padding-bottom: 0px;
      margin: 0;
      .cited-header-without-m {
        font-weight: 500;
        margin-bottom: 16px;
      }
    }
  }

  .contacts-page_links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 40px;
    @include l-sm {
      gap: 10px;
      flex-direction: column;
      margin-bottom: 0px;
      .footer-contacts-link {
        border: none !important;
      }
    }

    .footer-contacts-link {
      border: 1.5px solid black;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      &:hover {
        background-color: black;
        img {
          filter: invert(1);
        }
      }
    }
  }
}

.footer-contacts-page {
  color: #222222;
  //min-height: 650px;
  //line-height: 120%;
  padding-top: 60px;
  //max-width: 700px;
  //width: 100%;
  position: relative;

  @include l-xl {
    padding-top: 40px;
  }

  @include l-sm {
    padding: 24px 0 0 0;
  }

  .contacts-links {
    .footer-contacts-link {
      border: 1.5px solid black;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      &:hover {
        background-color: black;
        img {
          filter: invert(1);
        }
      }
    }
  }

  &_map {
    width: 100%;
    height: calc(100vh - 70px);
    position: relative;
    display: flex;
    justify-content: center;
    &_button {
      position: absolute;
      top: 25px;
      z-index: 1500;
      width: 301px;
      max-width: 301px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      &:hover {
        img {
          filter: invert(0);
        }
      }
      img {
        filter: invert(1);
      }
    }
  }

  &_lines {
    position: absolute;
    right: 32px;
    top: 112px;
    &_line,
    &_dot {
      background-color: #bf3535;
      width: 3px;
      height: 3px;
      margin: 0 0 16px 0;
    }

    &_line {
      height: 44px;
    }
  }

  &_contact-form {
    display: block;

    @include l-md {
      display: none;
    }
  }

  &_top {
    font-family: "Inter";
    font-weight: 500;
    font-size: 60px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 120%;
    padding-bottom: 15px;
    border-bottom: 6px solid $redColor;
    margin-bottom: 45px;
    max-width: 460px;
    @include l-xl {
      margin-bottom: 30px;
    }
    @include l-md {
      padding-bottom: 20px;
      margin-bottom: 30px;
      font-size: 42px;
    }

    @include l-sm {
      font-size: 24px;
      padding: 0 0 16px 0;
      margin: 0 0 40px 0;
      border-bottom: 3px solid $redColor;
    }
  }

  //footer-contacts-page_blocks

  &_blocks {
    margin-bottom: 48px;
    @include l-xl {
      margin-bottom: 35px;
    }
  }

  &_block {
    display: flex;
    align-items: start;
    gap: 20px;
    color: white;
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 45px;
    }

    @include l-md {
      margin-bottom: 20px;
      gap: 16px;
    }
    a {
      color: white;
      text-decoration: none;
      &:hover,
      &:active {
        color: white;
        text-decoration: underline;
      }
    }
    &-image {
      width: 24px;
      height: 24px;

      @include l-sm {
        width: 16px;
        height: 16px;
      }
    }
    &_header {
      font-family: "Inter";
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 15px;
      color: #222222 !important;
      @include l-md {
        margin-bottom: 8px;
        font-size: 16px;
      }
    }
    &_text {
      font-family: "Inter";
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
      color: #222222 !important;
      @include l-md {
        font-size: 14px;
      }
    }
  }
  &_links {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    @include l-xl {
      margin-bottom: 10px;
    }

    @include l-md {
      justify-content: end;
      flex-direction: column;
      align-items: end;

      gap: 32px !important;
    }
  }
}

.footer__contacts {
  color: white;
  //min-height: 650px;
  //line-height: 120%;
  padding-top: 160px;
  max-width: 700px;
  //width: 100%;
  @include l-md {
    padding-top: 65px;
  }

  @include l-md {
    padding: 48px 0 0 0;
  }

  .footer-contacts-top {
    font-family: "Inter";
    font-weight: 500;
    font-size: 60px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 120%;
    padding-bottom: 16px;
    border-bottom: 6px solid $redColor;
    margin-bottom: 60px;
    max-width: 460px;
    @include l-xl {
      margin-bottom: 30px;
    }
    @include l-md {
      padding-bottom: 8px;
      margin-bottom: 42px;
      font-size: 42px;
      border-bottom: 3px solid #bf3535;
    }

    @include l-sm {
      font-size: 24px;
    }
  }
  .footer-contacts-blocks {
    margin-bottom: 60px;
    @include l-xl {
      margin-bottom: 35px;
    }
    @include l-sm {
      margin: 0 0 45px 0;
    }
  }
  .footer-contacts-block {
    display: flex;
    align-items: start;
    gap: 20px;
    color: white;
    margin-bottom: 40px;
    @include l-md {
      margin-bottom: 32px;
      gap: 16px;
    }

    a {
      color: white;
      text-decoration: none;
      &:hover,
      &:active {
        color: white;
        text-decoration: underline;
      }
    }
    .footer-contacts-block-image {
      width: 24px;
      height: 24px;

      @include l-md {
        width: 16px;
        height: 16px;
      }
    }
    .footer-contacts-block-header {
      font-family: "Inter";
      font-weight: 600;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include l-md {
        margin-bottom: 8px;
        font-size: 16px;
      }
    }
    .footer-contacts-block-text {
      font-family: "Inter";
      font-weight: 300;
      font-size: 16px;
      line-height: 120%;
      & > div {
        &:not(:last-child) {
          margin: 0 0 10px 0;
        }
      }
      // & > br {
      //   margin: 0 0 10px 0;
      // }
      @include l-md {
        font-size: 12px;
        & > div {
          &:not(:last-child) {
            margin: 0 0 8px 0;
          }
        }
      }
    }
  }

  .footer-contacts-links {
    margin-bottom: 77px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @include l-xl {
      margin-bottom: 16px;
    }

    @include l-md {
      justify-content: end;
      flex-direction: column;
      align-items: end;

      gap: 32px;
    }
    @include l-sm {
      position: absolute;
    }
  }
  .footer-contacts-link {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1.5px solid #ffffff;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(0px 0px 11.1367px rgba(0, 0, 0, 0.3));
    border-radius: 100px;

    @include l-md {
      width: 32px;
      height: 32px;
      border: none;
    }

    .hover-image {
      display: none;
    }
    .hover-image,
    .default-image {
      width: 22px;
      height: fit-content;
    }
    &:hover {
      border: 1.5px solid #ffffff;
      background: white;
      .default-image {
        display: none;
      }
      .hover-image {
        display: block;
      }
    }

    &.mobile {
      border: none;
      background-color: transparent;

      .default-image {
        display: block;
      }
    }
  }
}
.footer-contacts-bottom {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  padding: 10px 0;
  align-items: flex-start;

  // @include l-md {
  //   display: none !important;
  // }
  &-policy {
    @include l-md {
      display: none;
    }
    &-rent {
      display: flex;
      align-items: end;
      color: #babcbf;
      margin-left: 4px;
      gap: 4px;
      a {
        color: #babcbf;
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        @include lg {
          &:not(:last-child) {
            margin: 0 10px 0 0;
          }
        }

        @include l-sm {
          font-size: 8px;
          font-weight: 500;
        }

        a {
          text-decoration: underline;
        }
        &:hover,
        &:active {
          color: #babcbf;
          text-decoration: none;
        }
      }
    }
  }

  @include l-xl {
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    align-items: flex-start;
  }

  @include l-sm {
    margin: 16px 0;
    padding: 0;
    padding-top: 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 6px;
    div {
      display: flex;
      flex-direction: row;
      align-self: center;
    }

    & > span {
      font-size: 8px;
    }
  }
}

.footer-contacts-bottom {
  display: flex;

  // @include l-sm {
  //   display: none;
  // }

  &-logo {
    gap: 8px;
    display: block;

    &_rent {
      display: flex;
      align-items: end;
      gap: 4px;
      span {
        // font-size-14 font-weight-light
        font-size: 14px;
        font-weight: 400;
        color: #babcbf;
        @include l-sm {
          font-size: 8px;
          line-height: 120%;
          font-weight: 500;
        }
      }
    }

    @include l-sm {
      gap: 4px;
    }
    span {
      // font-size-14 font-weight-light
      font-size: 14px;
      font-weight: 500;
      color: #babcbf;
      @include l-sm {
        font-size: 8px;
      }
    }
  }
}
.footer-contacts-bottom-element {
  color: #babcbf;
  font-family: "Inter";
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;
  @include lg {
    &:not(:last-child) {
      margin: 0 24px 0 0;
    }
  }

  @include l-sm {
    font-size: 8px;
    font-weight: 500;
  }

  a {
    text-decoration: underline;
  }
  &:hover,
  &:active {
    color: #babcbf;
    text-decoration: none;
  }
}
.footer__map-form {
  display: block;
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 50;
  @include l-xl {
    display: none;
    //position: relative;
    //margin: 2rem 0.5rem;
    //max-width: 100%;
    //left: unset;
    //bottom: unset;
  }
}
.footer__map-block {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 60%;
  max-width: 1100px;
  min-height: 580px;
  @include l-xl {
    width: 50%;
  }
  @include l-md {
    position: relative;
    width: 100%;
  }
  &.full {
    width: 100% !important;
    position: relative;
    max-width: unset;
    .map_container {
      min-height: 650px;
      @include l-md {
        min-height: 450px;
      }
    }
  }
}
