@-webkit-keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
    margin-bottom: 60px;
  }
  70% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0.5;
    margin-bottom: 30px;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0;
    margin-bottom: 0;
  }
}
@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
    margin-bottom: 60px;
  }
  70% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0.5;
    margin-bottom: 30px;
    padding: 0;
    height: 0;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0;
    margin-bottom: 0;
  }
}

@keyframes remove-height {
  0% {
  }
  100% {
    height: 0%;
    padding: 0px 0px;
    margin: 0px;
  }
}

.slide-out-bck-center {
  -webkit-animation: slide-out-bck-center 0.6s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-bck-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
}
.smooth-fade {
  visibility: hidden;
  opacity: 0;
  padding: 0px 0px;
  margin: 0 !important;
  box-sizing: border-box;
  height: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear, padding 0.6s ease 0.3s,
    margin 0.6s ease 0.3s, height 0.6s ease 0.3s;
}

.smooth-fade.show {
  visibility: visible;
  opacity: 1;
  height: auto;
  padding: auto;
  margin: auto;
  transition: opacity 0.3s linear, /* Срабатывает сразу */ visibility 0s 0s,
    /* Сразу делает видимым */ height 0.6s ease,
    /* Запускается через 0.3s */ padding 0.6s ease, margin 0.6s ease;
}
