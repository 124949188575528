.personal-account_transactions {
    .personal-account_transactions-actions {
        margin-bottom: 20px;

        @media (max-width: 1024px) {
            position: fixed;
            z-index: 2;
            margin-bottom: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            box-shadow: 0px -5px 32px 0px rgba(0, 0, 0, 0.05);
            padding: 12px 16px 16px;

            svg {
                width: auto;
                max-height: 14px;
            }
        }
    }

    &-balance {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: fit-content;
        padding: 20px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

        @media (max-width: 1024px) {
            width: 100%;
            padding: 12px 16px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        span {
            &.outcome {
                color: #b80600;
            }
        }

        &List {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
        }

        &Item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 8px 0;
            cursor: pointer;
            border-radius: 2px;

            & > * {
                position: relative;
                @media (min-width: 1025px) {
                    z-index: 1;
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: -22px;
                right: -22px;
                z-index: 0;
                pointer-events: none;
                transition: 0.2s;
                opacity: 0;
                background-color: #f2f3f6;

                @media (max-width: 1024px) {
                    left: -20px;
                    right: -20px;
                }
            }

            @media (min-width: 768px) {
                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            &.active {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -22px;
                    right: -22px;
                    z-index: 1;
                    pointer-events: none;
                    border: 2px solid #222;
                    border-radius: 2px;

                    @media (max-width: 1024px) {
                        left: -17px;
                        right: -17px;
                    }
                }
            }

            &.deposit {
                color: #737d89;
                // pointer-events: none;

                span {
                    opacity: 0.5;
                }
            }

            &.additional {
                align-items: baseline;

                span {
                    &:nth-child(1) {
                        span {
                            color: #606569;
                        }
                    }
                }
            }

            img {
                width: 22px;
                height: auto;
                margin-right: 15px;
            }

            span {
                display: block;
                font-size: 16px;
                font-weight: 500;

                &:last-child {
                    margin-left: auto;
                }

                @media (max-width: 1024px) {
                    font-size: 14px;

                    span {
                        font-size: 12px;
                        color: #606569;
                    }
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid #f2f3f6;
        text-transform: uppercase;
        font-size: 20px;

        &.hidden {
            margin-bottom: 0;
            padding-bottom: 0;
            border: 0;

            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            width: 16px;
            height: auto;
        }

        span {
            font-size: 16px;
            display: inline-flex;
        }

        @media (max-width: 1024px) {
            font-size: 14px !important;
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: -10px;
        padding: 0;
    }

    &-day {
        width: 100%;
        margin: 10px;
        display: flex;
        flex-direction: column;

        ul {
            width: 100%;
            padding: 0;
            margin: 0;
        }

        li {
            width: 100%;
            min-height: 70px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 20px;
            border-radius: 5px;
            background: #fff;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            font-size: 15px;
            font-weight: 500;
            line-height: 120%;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            @media (max-width: 1024px) {
                padding: 8px 0;
                box-shadow: none;
                font-size: 14px;
            }
        }
    }

    &-date {
        width: fit-content;
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #222;
        margin-bottom: 10px;
        border-radius: 5px;
        background: rgba(115, 125, 137, 0.2);
    }

    &-icons {
        margin-right: 20px;

        img {
            width: 40px;
            height: auto;
        }

        @media (max-width: 1024px) {
            margin-right: 16px;

            img {
                width: 33px;
            }
        }

        &.transaction {
            position: relative;
            width: 40px;
            height: 40px;

            @media (max-width: 1024px) {
                width: 33px;
                height: 33px;
            }

            img {
                position: absolute;
                width: 20px;

                &:nth-child(1) {
                    top: 0;
                    right: 0;
                }

                &:nth-child(2) {
                    left: 0;
                    bottom: 0;
                }

                @media (max-width: 1024px) {
                    width: 16px;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                background: url("../../../../images/personal-account/transactions/arrow-bot.svg") no-repeat;
                background-position: center center;

                @media (max-width: 1024px) {
                    width: 16px;
                    height: 16px;
                }
            }

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 20px;
                height: 20px;
                background: url("../../../../images/personal-account/transactions/arrow-top.svg") no-repeat;
                background-position: center center;

                @media (max-width: 1024px) {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &-time {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;

        div {
            &:nth-child(1) {
                margin-bottom: 5px;

                @media (max-width: 1024px) {
                    margin-bottom: 4px;
                }
            }

            &:nth-child(2) {
                color: #b4b4b4;
            }
        }

        @media (max-width: 1024px) {
            display: none;
        }
    }

    &-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;
        max-width: 60%;

        div {
            &:nth-child(1) {
                margin-bottom: 5px;

                @media (max-width: 1024px) {
                    margin-bottom: 4px;
                }
            }

            &:nth-child(2) {
                &.transaction {
                    color: #737d89;
                }
                &.income {
                    color: #008f4b;
                }
                &.outcome {
                    color: #b80600;
                }

                @media (max-width: 1024px) {
                    font-size: 10px;
                }
            }
        }

        a {
            color: #222;
        }
    }

    &-amount {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
        font-size: 18px;

        div {
            &:nth-child(1) {
                @media (max-width: 1024px) {
                    margin-bottom: 4px;
                }
            }

            &:nth-child(2) {
                display: none;
                color: #737d89;
                font-size: 10px;

                @media (max-width: 1024px) {
                    display: block;
                }
            }
        }

        @media (max-width: 1024px) {
            font-size: 14px;
        }
    }

    &-filters {
        // padding-bottom: 24px;
        border-bottom: 1px solid #f2f3f6;

        .personal-account_transactions-balance {
            &:nth-child(1) {
                margin-top: 8px;
            }

            &:last-child {
                margin-bottom: 24px;
            }
        }
    }

    &-filtersHead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: #737d89;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;

        &.active {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            path {
                stroke: #737d89;
            }
        }
    }
}
