* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

// .container {
//   max-width: 1px;
// }

.sticky-no-scrollbar {
  width: 100%;
  max-height: calc(100vh - 120px);
  top: 120px;
  overflow-y: scroll;
  position: sticky;
  //padding-left: 10px;
  z-index: 90;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  scrollbar-width: none; /* for Firefox */

  &.top100 {
    max-height: calc(100vh - 100px);
    top: 100px;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-button {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e7e7e8;
    border: 0px none #ffffff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e7e7e8;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #e7e7e8;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border: 0px none #ffffff;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track:hover {
    background: #ffffff;
  }

  &::-webkit-scrollbar-track:active {
    background: #ffffff;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.red-border-attention {
  border-left: 6px #bf3535 solid;
  padding-left: 10px;
  font-style: italic;
  margin-top: 148px;
}

.sticky-no-scroll_car {
  @include l-md {
    overflow: unset;
    max-height: 100% !important;
    // position: unset;
    // z-index: 1;
  }
}

.text-default {
  color: $textColor !important;
}

.text-red-color {
  color: $redColor !important;
}

.text-red {
  color: $redColor !important;
}

.text-gray-color {
  color: $grayColor !important;
}

.text-lightgray {
  color: $lightGrayColor !important;
}

.text-hover-default {
  &:hover {
    color: $textColor !important;
  }
}

.text-hover-red-color {
  &:hover {
    color: $redColor !important;
  }
}

.text-hover-gray-color {
  &:hover {
    color: $grayColor !important;
  }
}

.text-hover-lightgray {
  &:hover {
    color: $lightGrayColor !important;
  }
}

.text-hover-white {
  &:hover {
    color: white !important;
  }
}

.bg-hover-default {
  &:hover {
    background-color: $textColor !important;
  }
}

.bg-hover-red-color {
  &:hover {
    background-color: $redColor !important;
  }
}

.bg-hover-gray-color {
  &:hover {
    background-color: $grayColor !important;
  }
}

.bg-hover-lightgray {
  &:hover {
    background-color: $lightGrayColor !important;
  }
}

.bg-hover-white {
  &:hover {
    background-color: white !important;
  }
}

.default-transition {
  transition: all 0.15s ease-in-out;
}

.long-transition {
  transition: all 0.3s ease-in-out;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-360 {
  transform: rotate(360deg);
}

.rotate-45-back {
  transform: rotate(-45deg);
}

.rotate-90-back {
  transform: rotate(-90deg);
}

.rotate-180-back {
  transform: rotate(-180deg);
}

.rotate-270-back {
  transform: rotate(-270deg);
}

.rotate-360-back {
  transform: rotate(-360deg);
}

.bg-default-color {
  background-color: $textColor !important;
}

.bg-red-color {
  background-color: $redColor !important;
}

.bg-gray-color {
  background-color: $grayColor !important;
}

.ls-001 {
  letter-spacing: 0.01em;
}

.ls-002 {
  letter-spacing: 0.02em;
}

.ls-003 {
  letter-spacing: 0.03em;
}

.ls-004 {
  letter-spacing: 0.04em;
}

html {
  max-width: 100%;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 120%;
}

body {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  max-width: 100%;
  background-color: rgba(245, 245, 245, 1);
  letter-spacing: 0.01em;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  .site {
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
    @media (min-width: 1920px) {
      max-width: 1920px;
      margin-left: calc((100vw - 1920px) / 2);
    }

    main {
      flex-grow: 1;
    }
  }
}

.no-top-padding {
  height: 80px;

  @include l-md {
    height: 58px;
  }
}

main {
  // min-height: 70vh;
  position: relative;
  overflow-x: hidden;
}

.tooltip {
  &-arrow {
    opacity: 0;
  }
}

.default-link {
  color: #babcbf;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  display: inline;
  background: transparent;
  border: unset;
  outline: none;

  &:hover {
    color: #808080;
    //text-decoration: underline ;
  }

  &:active {
    color: #494949;
  }

  &.dark {
    color: $textColor;

    &:active {
      color: #babcbf;
    }
  }

  &.underlined {
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

.contacts__form {
  padding: 30px 40px;
  background: white;
  max-width: 580px;
  width: 580px;
  height: 447px;
  margin: 0 auto;
  min-width: 400px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 500ms ease-in-out;
  @include l-lg {
    max-width: 100%;
    min-width: unset;
    padding: 18px 26px;
  }
  @include l-sm {
    width: 328px;
    height: 320px;
    padding: 16px;
  }

  &-footer > button {
    max-width: 152px;
    max-height: 52px;
    width: 152px;
    @include l-md {
      width: 100%;
    }
    @include l-sm {
      max-width: 100%;
      width: 100%;
      max-height: 48px;
      max-height: 41px;
      min-height: 41px;
      padding: 8px 25px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &_grid {
    display: grid;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns: 245px 245px;
    @include l-sm {
      grid-template-columns: 144px 144px;
      column-gap: 8px;
      row-gap: 8px;
    }
  }

  &.contacts__form-big {
    padding: 60px 100px;
    max-width: unset;
    min-height: 600px;
    height: 100%;
  }
}

.contacts__form-feedback {
  padding: 80px 100px 60px 100px;
  max-width: 680px;
  width: 680px;
  height: fit-content;
  @include l-xl {
    padding: 60px 60px 40px 60px;
  }
  @include l-lg {
    padding: 40px 50px 30px 50px;
  }
}

.contacts__form-input {
  outline: none;
  border: unset;
  border-bottom: 1px solid #e7e7e8;
  width: 100%;
  padding: 10px 15px;
  font-family: "Inter";
  font-size: 16px;
  line-height: 140%;
  transition: all 0.2s ease-in-out;
  position: relative;

  &[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  @include media-breakpoint-down(md) {
    font-size: 14px !important;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px !important;
    padding: 8px 12px;
  }

  @include l-sm {
    input {
      font-size: 12px !important;
      height: 33px;
    }
  }

  &::placeholder {
    color: #babcbf;
  }

  &:focus {
    border-bottom: 2px solid $textColor;
  }

  &.error {
    border-bottom: 2px solid $redColor;
    color: $redColor;
    z-index: 15;

    &:before {
      display: flex;
      align-items: center;
      text-align: end;
      content: "" attr(data-error) "";
      color: $redColor;
      position: absolute;
      z-index: 20;
      right: 0;
      top: 0;
      height: 100%;
      font-size: 12px;
      max-width: 30%;
      width: fit-content;
    }
  }

  &.small {
    padding: 7px 5px;
  }
}

.contacts__form-row {
  height: 42px;
  @include l-sm {
    height: 33px;
  }
  input {
    height: 42px;
    @include l-sm {
      height: 33px;
    }
  }
}

.contacts__form-textarea-container {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 30px;
    z-index: 50;
    pointer-events: none;
  }

  .contacts__form-textarea {
    @extend .contacts__form-input;
    min-height: 100px;
    height: 100%;
    resize: none;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-button {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e7e7e8;
      border: 0px none #ffffff;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #e7e7e8;
    }

    &::-webkit-scrollbar-thumb:active {
      background: #e7e7e8;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
      border: 0px none #ffffff;
      border-radius: 1px;
    }

    &::-webkit-scrollbar-track:hover {
      background: #ffffff;
    }

    &::-webkit-scrollbar-track:active {
      background: #ffffff;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}

.form-datepicker_container {
  display: flex;
  max-width: 450px;
  box-shadow: 0px 0px 20px 0px #0000001a;

  @media (max-width: 990px) {
    transform: translateX(-36px);
  }
  @media (max-width: 767px) {
    transform: translateX(-18px);
  }
  @media (max-width: 576px) {
    transform: 0;
  }
}

.open-map-button {
  transition: all 0.3s ease-in-out;
  position: absolute;
  display: flex;
  gap: 10px;
  bottom: 110px;
  right: 410px;
  font-size: 12px;
  font-weight: 500;
  z-index: 1061;
  cursor: pointer;
  @media (max-width: 1200px) {
    right: 660px;
  }
  @media (max-width: 990px) {
    right: 350px;
  }
  @media (max-width: 767px) {
    right: 360px;
    bottom: 70px;
  }
  &_large {
    transition: all 0.3s ease-in-out;
    position: absolute;
    display: flex;
    gap: 10px;
    bottom: 20px;
    font-size: 12px;
    font-weight: 500;
    z-index: 1061;
    cursor: pointer;
    right: 1000px;
    text-wrap: nowrap;
    @media (max-width: 1200px) {
      right: 660px;
    }
    @media (max-width: 990px) {
      right: 350px;
    }
    @media (max-width: 767px) {
      right: 360px;
      bottom: 20px;
    }
  }
}

.form-map {
  transition: all 0.3s ease-in-out;
  &_container {
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: 100px;
    right: 0;
    z-index: 1060;
    height: 220px;
    width: 100%;
    @media (max-width: 1200px) {
      width: 798px !important;
      height: 120px !important;
    }
    @media (max-width: 990px) {
      width: 498px !important;
      height: 100px !important;
    }
    @media (max-width: 767px) {
      display: none;
    }
    &_large {
      transition: all 0.3s ease-in-out;
      position: absolute;
      z-index: 1060;
      height: 760px !important;
      width: 1140px !important;
      right: 0;
      bottom: 0px;
      @media (max-width: 1200px) {
        width: 798px !important;
        height: 650px !important;
      }
      @media (max-width: 990px) {
        width: 498px !important;
        height: 650px !important;
      }
      @media (max-width: 767px) {
        width: 498px !important;
        height: 500px !important;
      }
    }
  }
}

.radio-input {
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: flex-start;
  &::before {
    padding-top: 3px;
    content: url("./../images/index/radio-empty.svg");
  }
  &_checked {
    align-items: flex-start;
    display: flex;
    gap: 5px;
    cursor: pointer;
    &::before {
      padding-top: 3px;
      content: url("./../images/index/radio-checked.svg");
    }
  }
}

.input-wrap {
  max-width: 100%;
  width: 100%;
  min-height: 40px;
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
}

.site-btn {
  max-width: 221px;
  max-height: 37px;
  display: block;
  width: fit-content;
  outline: none;
  background: white;
  border: 1.5px solid $redColor;
  color: $textColor;
  font-size: 16px;
  font-weight: 600;
  // border-radius: 2px;
  line-height: 140%;
  transition: all 0.15s ease-in-out;
  padding: 12px 16px;
  text-transform: uppercase;
  text-decoration: none;

  @include l-sm {
    font-size: 12px !important;
    padding: 12px 16px;
  }

  &-mobile {
    background-color: #ffffff !important;
    border: none;
    padding: 0;
    filter: none !important;

    &:hover {
      background-color: #ffffff !important;
    }
  }

  svg {
    path {
      stroke: #222;
    }
  }

  &.small {
    padding: 8px 25px;
    //font-weight: 500;
  }

  &.index-btn-mobile {
    box-sizing: border-box;
    height: 20px;
    padding: 4px 8px;
    border-radius: 0;
    font-size: 10px !important;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    border-width: 1px;
  }

  &.extra-small {
    padding: 5px 15px;
    font-size: 14px;
    max-height: 27px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    @include l-sm {
      padding: 4px 8px;
      font-size: 10px;
    }
  }

  &.regular_text {
    text-transform: none;
  }

  &.fill-red {
    background: $redColor;
    border: 1px solid $redColor;
    color: white;
  }

  &:hover {
    background: $redColor;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    color: white;
    border-color: $redColor;

    svg {
      path {
        stroke: white;
      }
    }
  }

  &:active {
    background: $redColor;
    color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-color: $redColor;

    svg {
      path {
        stroke: #222222;
      }
    }
  }

  &.big {
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    max-height: 48px;

    @include l-md {
      font-size: 12px;
    }
  }

  &.dark {
    border-color: $textColor;

    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-color: $textColor;
      background: $textColor;
      color: white;
    }

    &:active {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-color: $textColor;
      background: $textColor;
      color: white;
    }
  }

  &.gray {
    border-color: $grayColor;
    color: $grayColor;
    box-shadow: unset;

    &:hover {
      background: white;
      box-shadow: unset;
      border-color: $grayColor;
      color: $grayColor;
    }

    &:active {
      background: white;
      box-shadow: unset;
      border-color: $grayColor;
      color: $grayColor;
    }
  }

  &.light {
    background: transparent;
    color: white;
    border: 1.5px solid #ffffff;
    filter: drop-shadow(0px 0px 10px rgba(55, 73, 96, 0.25));
    // border-radius: 2px;

    svg {
      path {
        stroke: white;
      }
    }

    @include l-sm {
      border: 1px solid #ffffff;
    }

    &:hover {
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      color: $textColor;
      svg {
        path {
          stroke: #222;
        }
      }
    }

    &:active {
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      color: $textColor;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

._alert {
  background: #ffffff;
  border: 1px solid rgba(191, 53, 53, 0.5);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 15px 20px;
  z-index: 30;
  position: relative;
  line-height: 120%;
  height: 70px;

  @include l-sm {
    padding: 8px 46px 8px 12px;
    gap: 16px;
  }

  a {
    color: $redColor;
    text-decoration: none;

    &:hover,
    &:active {
      color: $redColor;
      text-decoration: underline;
    }
  }

  ._alert__image {
    width: 32px;
    height: 32px;
    object-fit: contain;

    @include l-sm {
      width: 30px;
      height: 30px;
    }
  }

  ._alert__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    flex-grow: 1;
    @include l-md {
      font-size: 13px;
    }
  }

  ._alert__close {
    border: unset;
    outline: none;
    background: transparent;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 40;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.15s ease-in-out;
    background: white;
    color: $lightGrayColor;

    @include l-sm {
      height: 18px;
      width: 18px;
      right: 26px;
      top: 50%;
    }

    &:hover,
    &:active {
      color: $textColor;
    }
  }

  &.hidden {
    display: none;
  }
}

.overlay-bt {
  background: linear-gradient(
    0deg,
    rgba(black, 0.5) 0%,
    rgba(black, 0.3) 40%,
    rgba(black, 0) 100%
  );
}

.scroller {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: $textColor;
  border-radius: 10px;
  z-index: 60;
  border: unset;
  box-shadow: unset;
  outline: none;
  transition: all 0.4s ease-in-out;
  opacity: 0;

  &:hover {
    background: #444444;
  }

  @include l-md {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    border: 1px solid #f2f3f6;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      background: #fff;
    }
  }

  @include l-sm {
    border-radius: 0;
  }

  &.show {
    opacity: 1;
  }

  &.upper {
    bottom: 95px;
  }
}

.tooltip {
  z-index: 20 !important;
  position: fixed; /* Или оставьте fixed */
  max-width: 250px !important;
  max-height: 194px !important;
  opacity: 1 !important;
  width: fit-content;
}

.tooltip-content {
  transform: translate(-23px, -28px);
  z-index: 1400;
  width: fit-content;
  display: flex;
  flex-direction: column;
  min-width: 250px !important;
  min-height: 194px !important;
  background-color: white;
  padding-left: 50px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 10px;
  align-items: start;
  gap: 5px;
  height: fit-content;
  box-sizing: border-box;
  position: relative;
  opacity: 1;
  // position: relative;
  justify-content: space-between;
  &_close {
    position: absolute;
    right: -25px;
    top: -5px;
    z-index: 1500;
    cursor: pointer;
  }
  img {
    position: absolute;
    left: 3px;
    top: -12px;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  p {
    text-align: start;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
  button {
    width: 132px;
    height: 31px;
    min-height: 31px;
    min-width: 132px;
    border: 1.5px solid #222222;
    cursor: pointer;
    background-color: transparent;
    margin-top: 13px;
    margin-bottom: 5px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
  }
  .title {
    font-size: 22px;
    font-weight: 600;
    color: #222222;
    margin: 0;
    text-wrap: nowrap;
    margin-bottom: 13px;
  }
  @include l-sm {
    transform: translate(-50%, 5px);
    min-width: 240px !important;
    min-height: 127px !important;
    transform: 0;
    z-index: 1400;
    color: white;
    width: fit-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 12px;
    align-items: center;
    gap: 5px;
    height: fit-content;
    box-sizing: border-box;
    opacity: 1;
    // position: relative;
    justify-content: center;
    &_close {
      display: none;
    }
    img {
      display: none;
    }
    .title {
      color: white;
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      text-wrap: nowrap;
    }
    p {
      text-align: center;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
    }
    button {
      width: 132px;
      height: 31px;
      min-height: 31px;
      min-width: 192px;
      color: white;
      border: 1.5px solid white;
      cursor: pointer;
      background-color: transparent;
      margin-top: 12px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.tooltip-inner {
  z-index: 20;
  position: absolute; /* Или оставьте fixed */
  background-color: #ffffff;
  border-radius: 0;
  width: fit-content;
  min-width: 250px;
  min-height: 194px;
  max-width: none !important;
  color: black;
  opacity: 1 !important;

  .title {
    font-size: 22px;
    font-weight: 600;
    color: #222222;
    margin: 0;
    text-wrap: nowrap;
    margin-bottom: 13px;
  }
}

.toggle_sent-data-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 44px;
  height: 44px;
  background: $redColor;
  border-radius: 8px;
  z-index: 60;
  border: unset;
  box-shadow: unset;
  outline: none;
  transition: all 0.4s ease-in-out;
  opacity: 0;

  @include media-breakpoint-down(md) {
    display: flex;
  }

  @include l-sm {
    border-radius: 0;
  }

  &.show {
    position: fixed;
    bottom: 20px;
    left: 20px;
    opacity: 1;
  }

  &-close {
    background: $textColor;
  }

  &-form {
    padding: 0 16px;
    position: fixed;
    bottom: 80px;
    left: 0;
    opacity: 0;
    transform: scale(0);
    z-index: 201;
    transition: all 0.5s ease-out;

    &.active {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.map-container {
  height: 100%;
  min-height: 580px;
}

.cookies {
  width: 400px;
  display: flex;
  //align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  left: 50%;

  background: rgba(0, 0, 0, 0.5);
  background-position: 20px center;
  background-repeat: no-repeat;
  background-image: url("./../images/common/cookies.png");
  background-size: 40px 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1px);

  border-radius: 5px;
  gap: 10px;
  transform: translate(-50%, 100%);
  max-width: 400px;
  padding: 10px 20px 10px 80px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  z-index: 60;
  border: unset;
  outline: none;
  transition: all 0.4s ease-in-out;
  opacity: 0;

  &_header {
    font-size: 14px;
    font-weight: 500;

    @include l-sm() {
      font-size: 12px;
    }
  }

  &_description {
    font-size: 14px;

    @include l-sm() {
      font-size: 10px;
    }
  }

  &.show {
    opacity: 1;
    transform: translate(-50%, 0%);
  }

  &:hover {
    background-color: #444444;
  }

  @include l-sm() {
    padding: 10px 12px 10px 70px;
    background-position: 15px center;

    width: calc(100% - 24px);
  }
}

.modal-template {
  display: flex;
  // border-radius: 3px;
  // overflow: hidden;

  .modal-image-block {
    width: 620px;
    min-width: 620px;
    @include l-xl {
      display: none;
    }
    display: flex;
    flex-direction: column;
    background-size: cover;
    justify-content: flex-end;
    padding: 30px 40px;
  }

  .modal-content-block-container {
    width: 530px;
    min-width: 530px;

    @include l-xl {
      width: 100%;
      min-width: 100%;
    }
  }

  .modal-content-block {
    min-height: 760px;
    padding: 30px 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    height: 100%;
    position: relative;
    @include l-xl {
      min-height: 650px;
    }
    @include l-md {
      min-height: 500px;
      padding: 16px 20px;
    }
    .site-btn {
      padding: 10px 25px;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }
}

.call-request-text-header {
  font-family: "Inter";
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 15px;

  @include l-md {
    font-size: 28px !important;
  }
}

.call-request-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;

  @include l-md {
    font-size: 14px !important;
  }
}

.return_atention {
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f2f3f6;
  border-radius: 25px;
  padding-left: 5px;
  gap: 10px;
  margin-top: 20px;
  @include l-sm {
    height: 40px;
    padding-right: 25px;
  }
}

.call-content-text-header {
  font-family: "Inter";
  font-weight: 600;
  font-size: 40px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 5px;

  @include l-md {
    font-size: 28px !important;
  }
}

.call-content_close-btn {
  margin: 0 0 110px 0;

  @include l-lg {
    margin: 0 0 64px 0;
  }
  @include l-md {
    margin: 0 0 38px 0;
  }
}

.custom-phone-placeholder {
  &::placeholder {
    background: linear-gradient(
      to left,
      rgba(186, 188, 191, 0.5) 0%,
      rgba(186, 188, 191, 0.5) 95%,
      rgba(186, 188, 191, 1) 15%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent; /* Для других браузеров */
  }
}

.call-content-text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #606569;
  margin-bottom: 5px;
  @include l-md {
    font-size: 14px !important;
  }
  @include l-sm {
    font-size: 12px !important;
  }
}

.call-content-input {
  padding-bottom: 12px;
  position: relative;
  font-size: 16px;
  // @include l-md {
  //   width: 50%;
  // }
  &.error {
    .contacts__form-input {
      z-index: 15;
    }

    &:before {
      display: flex;
      align-items: center;
      text-align: end;
      content: "" attr(data-error) "";
      color: $redColor;
      position: absolute;
      z-index: 20;
      right: 0;
      top: 0;
      height: 100%;
      font-size: 12px;
      max-width: 50%;
      width: fit-content;
    }
  }

  &.small-form-input {
    &:before {
      bottom: 0px;
      left: 0px;
      right: unset;
      top: unset;
      height: unset;
      font-size: 10px;
      width: 100%;
      max-width: unset;
    }
  }
}

.file-input {
  height: 70px;
  border-radius: 2px;
  border: 2px solid var(--BG, #f2f3f6);
  background: var(--default-white, #fff);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  @include l-md {
    height: 40px;
    border: 1.5px solid #e7e7e8;
    box-shadow: none;
    padding: 0 12px 0 0;
  }

  &.selected {
    justify-content: left;
  }

  &_selected-image {
    height: 100%;
    width: 70px;
    border-radius: 2px;
    background: linear-gradient(
        228deg,
        rgba(78, 84, 89, 0.57) 27.81%,
        rgba(78, 84, 89, 0.58) 44.96%,
        rgba(78, 84, 89, 0.52) 93.65%
      ),
      lightgray -11.528px -2px / 134.933% 106.061% no-repeat;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

    @include l-md {
      width: 40px;
    }
  }

  &_icon {
    display: block;
    @include l-md {
      display: none;
    }
  }

  & > p {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.32px;
    text-transform: uppercase;
    margin: 0 0 0 20px;

    @include l-md {
      font-size: 12px;
    }
  }
  & > input {
    display: none;
  }
}

.shadowed-bg {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.1);
}

.cited {
  padding: 0 0 0 30px;
  border-left: 6px solid $redColor;

  &-mobile_divider {
    display: none;
    width: 24px;
    height: 4px;
    background-color: $redColor;
    margin: 0 0 16px 0;

    @include l-sm {
      display: block;
    }
  }

  margin: 76px 0 80px 0;

  @include l-md {
    margin: 44px 0 44px 0;
    background: var(--default-white, #fff);
    box-shadow: 0px 15px 24px -15px rgba(0, 0, 0, 0.08);
  }

  @include l-sm {
    margin: 24px 0;
    padding: 0;

    border: none;
  }

  &-attention {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #babcbf;
    @include l-sm {
      font-size: 9px;
    }
  }

  &-header {
    font-size: 50px;
    margin: 0 0 32px 0;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 100%;

    &-without-m {
      font-size: 50px;
      margin: 0 0 32px 0;
      line-height: 100%;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      @include l-md() {
        font-size: 42px;
        margin: 0 0 26px 0;
      }
      @include l-sm() {
        font-size: 32px;
        margin: 0 0 12px 0;
      }
    }

    //font-size-50 mb-px-30 font-weight-semibold text-uppercase line-height-120

    @include l-md() {
      font-size: 42px;
      margin: 0 0 26px 0;
    }
    @include l-sm() {
      font-size: 32px;
      margin: 0 0 8px 0;
    }
  }

  &-attention {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    color: #babcbf;
    margin: 0 0 30px 0;
    line-height: 140%;
    @include l-md() {
      font-size: 9px;
      margin: 0 0 16px 0;
    }
  }

  &-description {
    font-size: 16px;
    // margin: 0 0 24px 0;
    font-weight: 500;
    //line-height: 16.8px;
    //font-size-16 mb-px-20 font-weight-medium line-height-140 ls-001
    &.second {
      margin: 20px 0 40px 0;
    }
    @include l-md() {
      font-size: 14px;
      &.second {
        margin: 18px 0;
      }
    }

    @include l-md() {
      font-size: 12px;

      &.second {
        margin: 8px 0 24px 0;
      }
    }
  }
}

.small-black-btn {
  outline: none;
  padding: 5px 10px;
  color: white;
  background: $textColor;
  border-radius: 2px;
  border: unset;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #4b4b4b;
  }
}

.cited-block {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 12px 20px 12px 70px;
  position: relative;
  font-size: 15px;
  line-height: 140%;
  font-weight: 500;
  display: block;
  width: fit-content;

  &:not(:last-child) {
    margin: 0 0 25px 0;
  }

  letter-spacing: 0.01em;

  &-container {
    margin: 0 0 42px 0;

    @include l-md {
      margin: 0 0 28px 0;
    }

    @include l-sm {
      margin: 16px 0 0 0;
    }
  }

  & > br {
    display: none;
  }

  @include l-sm() {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    &:not(:last-child) {
      margin: 0 0 8px 0;
    }
    font-size: 12px;
    padding: 8px 20px 8px 44px;
    width: 100%;

    & > br {
      display: block;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    content: "";
    border-radius: 2px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    background-color: $textColor;
    background-image: url("./../images/common/contract.svg");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    @include l-sm {
      width: 33px;
      // height: 33px;
      background-size: 22px 22px;
    }
  }
  &:nth-child(1):before {
    background-image: url("./../images/common/contract.svg");
    @include l-sm {
      height: 33px;
    }
  }

  &:nth-child(2):before {
    background-image: url("./../images/common/shine-car.svg");
    @include l-sm {
      height: 33px;
    }
  }

  &:nth-child(3):before {
    background-image: url("./../images/common/keys-check.svg");
  }
}

.leasing__imaged-block {
  padding-left: 118px;
  padding-right: 10px;
  position: relative;
  min-height: 88px;
  margin: 0 0 40px 0;
  @include l-md {
    margin: 0 0 32px 0;
  }

  &_divider {
    width: 30px;
    height: 4px;
    background-color: $redColor;

    display: none;

    @include l-md {
      display: block;
    }
  }

  &_header {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 24px;
    margin: 0 0 10px 0;

    @include l-sm {
      font-size: 18px;
      margin: 0 0 8px 0;
    }
    //font-weight-semibold font-size-24 mb-px-10 text-uppercase
  }

  &_description {
    font-weight: 500;
    font-size: 16px;
    margin: 10px 0 0 0;
    max-width: 350px;

    &.options {
      max-width: 450px;
    }

    @include l-sm {
      margin: 8px 0 0 0;
      font-size: 12px;
    }
    //font-weight-medium font-size-16 line-height-140 ls-001
  }

  @include l-sm {
    padding: 0 0 0 60px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 88px;
    width: 88px;
    border: 2px solid #f2f3f6;
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 9.83351px 0px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    background-color: #ffffff;
    background-size: 48px 48px;
    background-position: center;
    background-repeat: no-repeat;

    @include l-sm {
      width: 44px;
      height: 44px;

      background-size: 22px 22px;
    }
  }

  &.li-bank {
    &:before {
      background-image: url("./../images/index/bank.png");
    }
  }

  &.li-jewel {
    &:before {
      background-image: url("./../images/index/jewel.png");
    }
  }

  &.li-card {
    &:before {
      background-image: url("./../images/index/cards.png");
    }
  }

  &.li-document {
    &:before {
      background-image: url("./../images/index/document.png");
    }
  }
}
.rent-scroller {
  bottom: 90px;
}

.faq {
  padding-bottom: 100px;
  margin-top: 80px;

  @include l-sm {
    margin: 30px 0;
  }
  //style={{marginTop:'40px', marginBottom:'40px'}}
  &_header {
    font-family: "Inter";
    font-weight: 600;
    font-size: 52px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $textColor;
    margin-bottom: 20px;
    letter-spacing: 0.5px;

    @include l-md {
      font-size: 44px;
      letter-spacing: normal;
    }

    @include l-sm {
      font-size: 32px;
      margin-bottom: 12px;
    }
  }

  &_questions {
    margin: 0 0 80px 0;

    @include l-md {
      margin: 0 0 70px 0;
    }

    @include l-sm {
      margin: 0 0 40px 0;
    }
  }

  &_description {
    color: #606569;
    font-size: 16px;
    margin: 0 0 80px 0;

    @include l-md {
      font-size: 14px;
    }

    @include l-sm {
      font-size: 12px;
      margin: 0 0 40px 0;
    }

    &-top {
      margin: 0;

      @include l-md {
        width: 300px;
      }
    }

    //text-gray-color font-inter-medium
  }
}

.foldable {
  margin-bottom: 35px;
  width: 100%;
  max-width: 620px;
  @include l-sm {
    margin: 0 0 24px 0;
  }

  .foldable-header {
    width: 100%;
    background: transparent;
    outline: none;
    border: unset;
    display: flex;
    text-align: start;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 10px 0;
    border-bottom: 2px solid #f2f3f6;
    font-family: "Inter";
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: $textColor;
    position: relative;

    @include l-md {
      font-size: 16px;
    }

    @include l-sm {
      font-weight: 500;
      font-size: 14px;
      padding: 0 0 8px 0;
    }
  }

  .foldable-header-image {
    transition: all 0.4s ease-in-out;
    width: 24px;
    height: 24px;
    object-fit: contain;
    @include l-md {
      height: 18px;
      width: 18px;
    }

    &_desk {
      width: 24px;
      height: 24px;
      transition: all 0.4s ease-in-out;

      &.turned {
        transform: rotate(180deg) translateX(3px);
        // path {
        //   stroke: #bf3535;
        // }
      }
    }

    &_mobile {
      width: 18px;
      height: 18px;
      transition: all 0.4s ease-in-out;

      &.turned {
        transform: rotate(180deg) translateX(3px);
        path {
          // fill: red;
          stroke: #bf3535;
        }
      }
    }

    &.foldable-header-image-small {
      width: 18px;
      height: 18px;
    }

    &.turned {
      transform: rotate(180deg);
    }
  }

  .foldable-content {
    padding: 10px 0 10px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #222222;
    white-space: pre-wrap;

    @include l-md {
      font-size: 14px;
    }
    @include l-sm {
      font-size: 12px;
      padding-bottom: 8px;
    }
  }

  &.foldable-mobile {
    width: 100%;
  }

  &.foldable-small {
    margin-bottom: 20px;
    max-width: 400px;
    width: 100%;

    .foldable-header {
      font-size: 14px;
      font-weight: 500;
      padding: 0 0 5px 0;
    }

    .foldable-content {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
    }
  }

  .foldable-ol,
  .foldable-ul {
    margin-right: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      //display: flex;
      padding-bottom: 10px;
      padding-left: 15px;
      position: relative;

      &::before {
        color: $textColor;
        font-size: 12px;
        font-weight: bold;
        //border: 1.5px solid #E7E7E8;
        border-radius: 100%;
        color: #babcbf;
        width: 20px;
        min-width: 20px;
        height: 17px;

        display: flex;
        align-items: center;
        justify-content: start;
        padding: 0;
        margin-top: -1px;
        line-height: 1;
      }
    }
  }

  .foldable-ol li::before {
    content: attr(data-marker);
  }

  .foldable-ul li::before {
    position: absolute;
    top: 6px;
    left: 0;
    content: "";
    background: #606569;
    border-radius: 1px;
    height: 5px;
    width: 5px;
    border-radius: 20px;
    max-width: 5px;
    min-width: 5px;
    max-height: 5px;
    min-height: 5px;
    display: block;
  }
}

@for $i from 1 through 40 {
  .anim-duration-#{$i*100} {
    transition-duration: $i * 100ms !important;
  }
}

@for $i from 1 through 50 {
  .anim-delay-#{$i*100} {
    transition-delay: $i * 100ms !important;
  }
}

.anim-enter-opacity {
  @include animated-enter(
    $directionX: "none",
    $directionY: "none",
    $intensityX: 0,
    $intensityY: 0
  );
}

@for $i from 1 through 10 {
  .anim-enter-start-#{$i} {
    @include animated-enter(
      $directionX: "left",
      $directionY: "none",
      $intensityX: $i,
      $intensityY: 0
    );
  }
  .anim-enter-end-#{$i} {
    @include animated-enter(
      $directionX: "right",
      $directionY: "none",
      $intensityX: $i,
      $intensityY: 0
    );
  }
  .anim-enter-top-#{$i} {
    @include animated-enter(
      $directionX: "none",
      $directionY: "top",
      $intensityY: $i,
      $intensityX: 0
    );
  }
  .anim-enter-bottom-#{$i} {
    @include animated-enter(
      $directionX: "none",
      $directionY: "bottom",
      $intensityY: $i,
      $intensityX: 0
    );
  }
  @for $j from 1 through 10 {
    .anim-enter-start-#{$i}-bottom-#{$j} {
      @include animated-enter(
        $directionX: "left",
        $directionY: "bottom",
        $intensityX: $i,
        $intensityY: $j
      );
    }
    .anim-enter-end-#{$i}-bottom-#{$j} {
      @include animated-enter(
        $directionX: "right",
        $directionY: "bottom",
        $intensityX: $i,
        $intensityY: $j
      );
    }
    .anim-enter-start-#{$i}-top-#{$j} {
      @include animated-enter(
        $directionX: "left",
        $directionY: "top",
        $intensityX: $i,
        $intensityY: $j
      );
    }
    .anim-enter-end-#{$i}-top-#{$j} {
      @include animated-enter(
        $directionX: "right",
        $directionY: "top",
        $intensityX: $i,
        $intensityY: $j
      );
    }
  }
}

@for $i from 0 through 10 {
  .opacity-#{$i*10} {
    opacity: $i * 10% !important;
  }
}

@for $i from 6 through 38 {
  .font-size-#{$i*2} {
    font-size: $i * 2px !important;
  }
}

@for $i from 20 through 30 {
  .line-height-#{$i*5} {
    line-height: $i * 5% !important;
  }
}

@for $i from 1 through 20 {
  .gap-px-#{$i*5} {
    gap: $i * 5px !important;
  }
  .gap-x-px-#{$i*5} {
    column-gap: $i * 5px !important;
  }
  .gap-y-px-#{$i*5} {
    row-gap: $i * 5px !important;
  }
}

@for $i from 1 through 60 {
  .m-px-#{$i*5} {
    margin: $i * 5px !important;
  }
  .mx-px-#{$i*5} {
    margin-left: $i * 5px !important;
    margin-right: $i * 5px !important;
  }
  .ms-px-#{$i*5} {
    margin-left: $i * 5px !important;
  }
  .me-px-#{$i*5} {
    margin-right: $i * 5px !important;
  }
  .my-px-#{$i*5} {
    margin-top: $i * 5px !important;
    margin-bottom: $i * 5px !important;
  }
  .mb-px-#{$i*5} {
    margin-bottom: $i * 5px !important;
  }
  .mt-px-#{$i*5} {
    margin-top: $i * 5px !important;
  }

  .p-px-#{$i*5} {
    padding: $i * 5px !important;
  }
  .px-px-#{$i*5} {
    padding-left: $i * 5px !important;
    padding-right: $i * 5px !important;
  }
  .ps-px-#{$i*5} {
    padding-left: $i * 5px !important;
  }
  .pe-px-#{$i*5} {
    padding-right: $i * 5px !important;
  }
  .py-px-#{$i*5} {
    padding-top: $i * 5px !important;
    padding-bottom: $i * 5px !important;
  }
  .pb-px-#{$i*5} {
    padding-bottom: $i * 5px !important;
  }
  .pt-px-#{$i*5} {
    padding-top: $i * 5px !important;
  }
}

.contacts-social {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $textColor;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  border-radius: 100px;
  transition: all 0.1s ease-in-out;

  .hover-image {
    display: none;
  }

  .hover-image,
  .default-image {
    width: 22px;
    height: 22px;
    object-fit: contain;
  }

  &:hover {
    //border: 2px solid #FFFFFF;
    background: $textColor;

    .default-image {
      display: none;
    }

    .hover-image {
      display: block;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: rotation 2s infinite;
}

.document-page {
  margin: 23px 0 20px;
  .document-title {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    margin-bottom: 40px;
    @include l-sm {
      font-weight: 600;
      font-size: 20px;
      text-align: start;
      margin-bottom: 24px;
    }
  }

  .document-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 40px;
    @include l-sm {
      font-weight: 500;
      font-size: 16px;
      text-align: start;
      margin-bottom: 12px;
    }
  }

  p {
    padding-left: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    @include l-sm {
      font-weight: 500;
      font-size: 12px;
      text-align: start;
    }
    &.document-not-padded {
      padding-left: 0;
    }

    a {
      color: $textColor;
      transition: all 0.1s ease-in-out;

      &:hover,
      &:active {
        //text-decoration: none;
        color: $redColor;
      }
    }
  }
}

.back_btn {
  background: white;
  color: black;
  border: 1.5px solid black;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  padding: 10px 20px;
  outline: none;
  position: fixed;
  bottom: 20px;
  left: 5%;
  box-shadow: unset;
  width: 165px;
  height: 37px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  @include l-md {
    display: none;
  }
}

.paginator {
  display: flex;
  // gap: 20px;
  justify-content: start;
  margin-bottom: 20px;

  @include l-sm {
    gap: 0px;
    margin: 0 0 20px 0;
  }

  .paginator-btn {
    display: flex;
    // border: $defaultColor;
    color: $defaultColor;
    outline: none;
    background: white;
    // border-radius: 2px;
    // border: 2px solid $defaultColor;
    justify-content: center;
    align-items: center;
    // min-height: 40px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    padding: 9px 14px;
    text-decoration: none;

    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    transition: all 0.15s ease-in-out;
    text-align: center;

    display: flex;
    align-items: center;

    @include l-sm {
      font-size: 14px;
      padding: 8px;
    }

    &:active {
      background: #f2f3f6;
      // color: white;
    }

    &:hover {
      // border: 2px solid #bf3535;
      // background-color: white;
      background: #f2f3f6;
      // color: #bf3535;
    }

    &.active {
      // background: $defaultColor;
      // color: white;
      background: #f2f3f6;
      &:hover {
        // border: 2px solid #bf3535;
        background: #f2f3f6;
        // color: #bf3535;
        // color: #bf3535;
      }
    }

    &.disabled {
      border-color: $grayColor !important;
      color: rgba($grayColor, 0.4);
      background: white !important;
    }
  }

  .paginator-arrow {
    @extend .paginator-btn;
    border-color: transparent;
    padding: 9px 0;
    border: none;
    margin: 0;
    width: 20px;

    @include l-sm {
      margin: 0;
    }

    &:hover,
    &:active {
      background: white;
      color: rgba($defaultColor, 0.7);
      border: none;
    }

    &.disabled {
      display: none;
      border-color: transparent !important;
      color: rgba($grayColor, 0.4);
      background: white !important;
    }
  }
}
.first-block {
  @include l-sm {
    padding: 24px 0;
    box-shadow: 0px 0px 24px 0px #00000014;
    .cited {
      margin: 0;
    }
  }
}

.second-block {
  margin: 80px 0;

  br {
    display: none;

    @include l-md {
      display: block;
    }
  }
  @include l-md {
    margin: 60px 0;
  }

  @include l-sm {
    padding: 32px 0 0 0;
    margin: 0;
  }
  //mt-px-80 mb-px-40

  &_header {
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.5;
    margin: 0 0 40px 0;
    font-size: 24px;
    color: #606569 !important;
    opacity: 0.6;

    @include l-sm {
      font-size: 16px;
      margin: 0 0 32px 0;
      width: 336px;
    }
    //text-gray-color font-weight-semibold font-size-24 text-uppercase opacity-50 mb-px-40
  }
}

.arrange-leasing {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.1);
  padding: 120px 0 100px 0;
  //shadowed-bg pt-px-100 pb-px-80

  @include l-md {
    padding: 70px 0 50px 0;
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08) inset;
  }
  @include l-sm {
    padding: 24px 0;
  }

  &_side {
    margin: 0 0 60px 0;
    padding: 0 0 0 42px;

    @include l-md {
      margin: 0 0 32px 0;
    }
    @include l-sm {
      padding: 0;
    }
    &:last-child {
      margin: 0;
    }
  }

  &_header {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 52px;
    border-left: 6px solid $redColor;
    padding: 0 0 0 30px;
    line-height: normal;

    &:first-child {
      @include l-sm {
        width: 190px;
      }
    }

    margin: 0 0 40px 0;
    //font-weight-semibold text-uppercase font-size-50 mb-px-40 line-height-120

    @include l-md {
      font-size: 36px;
      border: none;
      padding: 0;
    }

    @include l-sm {
      font-size: 20px;
      margin: 0 0 32px 0;
    }
  }

  &_options {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    color: #606569;
    opacity: 0.6;

    //font-size-24 font-weight-semibold text-uppercase mb-px-20 ls-001

    @include l-md {
      font-size: 18px;
    }
    @include l-sm {
      margin: 0 0 16px 0;
      font-size: 14px;
    }

    &-item {
      display: flex;
      align-items: start;
      margin: 0 0 15px 0;

      @include l-sm {
        margin: 0 0 12px 0;
        font-size: 12px;
        img {
          width: 17px;
          height: 17px;
        }
        .gray-text {
          color: #606569;
          font-size: 10px;
          font-weight: 500;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }

      & > p {
        font-size: 16px;
        margin: 0;
        line-height: initial;
        // br {
        //   display: none;
        // }

        @include l-md {
          font-size: 14px;
          br {
            display: block;
          }
        }
        @include l-sm {
          font-size: 12px;
        }
      }

      & > svg {
        width: 22px;
        height: 22px;

        @include l-md {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}

.step-block {
  padding: 100px 0 80px 0;
  // margin: 10px 0 0 0;
  @include l-md {
    padding: 80px 0 50px 0;
  }

  @include l-sm {
    padding: 32px 0 0 0;
  }

  .car-stat-block-item-name {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .car-stat-block-item-value {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .step-block_header {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 52px;

    // &:first-child {
    //   @include l-sm {
    //     width: 170px;
    //   }
    // }

    margin: 0 0 50px 0;
    //font-weight-semibold text-uppercase font-size-50 mb-px-40 line-height-120

    @include l-md {
      font-size: 36px;
      border: none;
      padding: 0;
      width: 400px;
    }

    @include l-sm {
      font-size: 20px;
      margin: 0 0 42px 0;
      width: 156px;
      line-height: 140%;
    }
  }

  .site-btn {
    max-width: 232px;
    font-weight: 600;
  }

  &_mobile {
    display: none !important;
    @include l-md {
      display: block !important;
    }

    &_characteristic {
      &_table {
        margin: 0;
        &.first {
          margin: 0 0 40px 0;
          @include l-sm {
            margin: 0;
          }
        }
        &-top {
          position: relative;

          & > img {
            position: absolute;
            top: -10px;
            left: -14px;
            z-index: 20;
          }
        }
        &-header {
          font-weight: 600;
          text-transform: uppercase;
          font-size: 20px;
        }

        &-desc {
          font-size: 10px;
          // margin: 16px 0 14px 0;
          font-weight: 500;
          margin: 22px 0 32px 0;
        }

        & > thead {
          background-color: #222222;
        }

        th {
          color: #ffffff;
          font-weight: 500;
          background-color: #222222;
        }

        tr {
          border-style: none;
          border-color: transparent;
        }

        td,
        th {
          font-size: 12px;
          padding: 12px;
        }

        &-right {
          text-align: right;
          font-weight: 500;
        }

        &-left {
          text-align: left;
        }
      }

      &_card {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        position: relative;
        padding: 0 3px 0 0;

        &:not(:last-child) {
          margin: 0 0 40px 0;
        }

        & > div {
          & > h2 {
            margin: 0 0 8px 0;
            font-weight: 600;
            font-size: 14px;
          }

          & > p {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }

        & > .first-number {
          color: $redColor;
        }

        & > h1 {
          font-size: 60px;
        }

        & > img {
          position: absolute;
          right: -12px;
          top: -20px;
          z-index: 20;
          transform: scale(1);
        }
      }
    }
  }

  &_descktop {
    // margin: 0 0 100px 0;
    row-gap: 0;
    display: block !important;
    @include l-md {
      display: none !important;
    }
    //gx-0 mb-px-100

    &_characteristic {
      display: grid;
      grid-template-columns: 1fr 100px 1fr;

      @include l-lg {
        grid-template-columns: 1fr 50px 1fr;
      }

      &_header {
        font-size: 72px;
        font-weight: 600;
        margin: 0 0 20px 0;
        text-transform: uppercase;

        @include l-lg {
          font-size: 40px;
          margin: 0 0 12px 0;
        }
        //font-size-72 line-height-140 font-weight-semibold mb-px-20 text-uppercase
      }
    }
  }
}

.leasing__step {
  padding-top: 177px;
  position: relative;
  line-height: 120%;

  @include l-lg {
    padding: 124px 0 0 0;
  }

  &.first {
    margin-right: 40px;
  }

  &.second {
    margin-right: 40px;
    margin-left: 40px;
  }

  &.third {
    margin-left: 40px;
  }

  .leasing__step-text {
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    z-index: 20;
    margin-bottom: 20px;
    line-height: 120%;

    @include l-lg {
      font-size: 26px;
    }
  }

  .leasing__step-bgtext {
    position: absolute;
    right: 0;
    top: 0;
    font-family: "Lato";
    font-size: 220px;
    font-weight: 700;
    line-height: 100%;
    z-index: 15;
    width: fit-content;
    height: 220px;
    color: #e7e7e8;

    @include l-lg {
      font-size: 150px;
    }
  }

  .leasing__step-side-image {
    position: absolute;
    height: 195px;
    width: 108px;
    background-image: url("./../images/common/helix.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 70%);
  }
}

.faq-block {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 0px 60px rgba(0, 0, 0, 0.1);
  padding: 120px 0 100px 0;

  @include l-md {
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08) inset;
  }

  &_body {
    margin: 0 0 100px 0;

    &-leftside,
    &-rightside {
      width: 50%;
      // justify-content: flex-end;

      & > div {
        max-width: 620px;
        width: 100%;
      }
    }

    &-rightside {
      display: flex;
      justify-content: flex-end;
    }

    // &-leftside {
    //   margin: 0 80px 0 0;
    // }

    @include l-md {
      margin: 0 0 70px 0;
      &-leftside,
      &-rightside {
        width: 100%;
        // justify-content: flex-end;

        & > div {
          max-width: 100%;
        }
      }
      &-rightside {
        justify-content: flex-start;
      }
    }

    @include l-sm {
      margin: 40px 0;
    }
  }
  //shadowed-bg pt-px-100 pb-px-80

  @include l-md {
    padding: 70px 0 50px 0;
  }

  @include l-sm {
    padding: 40px 0 30px 0;
  }

  &_header {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 52px;
    margin: 0 0 60px 0;

    //font-weight-semibold text-uppercase font-size-50 mb-px-40 line-height-120

    @include l-md {
      border-left: 4px solid #bf3535;
      font-size: 36px;
      padding: 0 0 0 24px;
    }

    @include l-sm {
      font-weight: 600;
      font-size: 20px;
      margin: 0 0 32px 0;
      padding: 0 0 0 12px;
    }
  }

  &_description {
    display: none;
    color: #606569;
    font-family: Inter;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.12px;
    margin: 0 0 44px 0;
    width: 300px;
    @include l-md {
      display: block;
    }

    @include l-sm {
      font-size: 12px;
    }
  }
}

.faq-contacts {
  //margin: 100px 0 90px;
  padding: 0 0 0 30px;
  border-left: 6px solid #bf3535;
  @include l-md {
    padding: 0 0 0 24px;
  }

  @include l-sm {
    padding: 0 0 0 12px;
    border-left: 4px solid #bf3535;
    height: 93px;
    // border: none;
  }

  .faq-contacts-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include l-md {
      font-size: 18px;
      margin: 0 0 12px 0;
    }

    @include l-sm {
      font-size: 14px;
    }
  }

  .faq-contacts-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 10px;

    @include l-md {
      font-size: 14px;
    }

    @include l-sm {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
}

.bottom-message {
  padding: 25px 40px;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin: 20px 0 0 0;

  @include l-md {
    padding: 20px 20px;
  }

  @include l-sm {
    padding: 14px 18px;
  }

  &-desc {
    display: flex;

    @include l-md {
      display: none;
    }
  }

  &-mobile {
    display: none;

    @include l-md {
      display: flex;
    }
  }

  // px-px-40 py-px-25 my-px-20 text-white d-flex justify-content-between align-items-bottom

  &_description {
    font-size: 20px;
    font-size: 600;
    margin: 0 0 16px 0;
    text-transform: none;

    @include l-md {
      font-size: 14px;
      margin: 0 0 10px 0;
    }

    @include l-sm {
      font-size: 12px;
      margin: 0;
    }

    // font-size-20 font-weight-medium mb-px-15 line-height-120
  }

  &_header {
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    // font-size-32 font-weight-semibold text-uppercase line-height-120

    @include l-md {
      font-size: 18px;
    }

    @include l-sm {
      font-size: 16px;
    }
  }

  &_btn {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    // d-flex flex-column justify-content-end align-items-bottom
  }
}

.form-check-error {
  input {
    border-color: $uiRedColor;
    box-shadow: 0 0 0px 1px rgba($uiRedColor, 0.7);
  }
}

.form-check-modal {
  display: flex;

  @include l-md {
    display: none;
  }
  span {
    letter-spacing: 0.01em;
  }
}

.rent-page_header {
  &-container {
    display: flex;
    justify-content: space-between;

    @include l-xl {
      display: block;
    }
  }

  &-collapse_container {
    display: flex;

    @include l-xl {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
    }
  }
  &-collapse {
    width: 400px;

    @include l-xl {
      width: 100%;

      & > .foldable-small {
        max-width: 100%;
      }
    }

    &:last-child {
      margin: 0 0 0 60px;

      @include l-xl {
        margin: 0;
      }
    }
  }
}

.rent-header-info {
  padding: 24px 0 16px 0;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
  &_body {
    padding: 0 0 0 20px;
    border-left: 4px solid #bf3535;
  }

  &_title {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.32px;
    text-transform: uppercase;
    margin: 0 0 8px 0;
  }

  &_description {
    color: #222;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.1px;
    margin: 0 0 8px 0;
  }

  &_phone {
    color: #bf3535;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.12px;
    margin: 0;
  }

  &_opener {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 20px 0 0 20px;

    & > p {
      margin: 0 0 0 4px;
      color: #babcbf;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 16.8px */
      letter-spacing: 0.24px;
      text-transform: uppercase;
    }
  }

  &_collapse-body {
    padding: 32px 0 0 0;
  }
}

.order-call_button {
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  & > svg {
    margin: 0 0 0 10px;
  }
  // font-weight-semibold d-flex align-items-center
}
