.mobile-modal {
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  overflow-x: scroll;
  display: none;
  background-color: #fff;
  vertical-align: middle;

  &.active {
    display: flex;
    flex-direction: column;
    .rdrCalendarWrapper {
      width: 100%;
    }
    .rdrDefinedRangesWrapper {
      width: 100%;
      box-shadow: none;
      .rdrStaticRanges {
        button:last-child {
          margin-top: 0;
        }
      }
    }
  }

  &_header {
    width: 100%;
    height: 264px;
    background-image: url("../../../../images/common/modal-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -20px 0 0 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-bottom {
      & > h3 {
        color: var(--default-white, #fff);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.16px;
        text-transform: uppercase;
        margin: 0 0 8px 0;
      }

      & > p {
        color: var(--default-white, #fff);
        font-size: 10px;
        font-weight: 600;
        line-height: 140%; /* 14px */
        letter-spacing: 0.1px;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }

  &_body {
    padding: 24px 16px 0 16px;
    background-color: #fff;
    height: 120% !important;

    & > h1 {
      color: var(--Text, #222);
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      margin: 0 0 8px 0;
    }

    & > p {
      color: var(--Grey-1, #606569);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 12px;

      font-weight: 500;
      line-height: 140%; /* 16.8px */
      margin: 0 0 24px 0;
    }

    &-start {
      & > form {
        margin: 68px 0 112px 0;
      }

      &_input {
        &:not(:last-child) {
          margin: 0 0 12px 0;
        }
      }
    }

    &-confirm {
      margin: 24px 0 0 0;
      &_input {
        display: flex;
        justify-content: space-between;
        text-align: center;

        input {
          text-align: center;
          font-size: 20px !important;
          padding: 12px 14px;
        }
      }

      &_timer {
        color: #babcbf;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.12px;
      }

      &_submit {
        margin: 60px 0 58px 0;
        &-send {
          padding: 12px 16px;
        }

        &-change {
          padding: 0 0 0 12px;
        }
      }
    }

    &-create {
      & > form {
        margin: 0 0 44px 0;
      }
    }

    &-payment {
      &_price {
        margin: 0 0 24px 0;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #222 !important;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.32px;
        text-transform: uppercase;
      }

      &_types {
        & > button {
          height: fit-content;
          & > .car-rent-modal-btn-image {
            padding: 16px 8px 16px 16px;
            width: fit-content;

            & > img {
              height: 20px;
              width: 20px;
            }
          }

          & > .car-rent-modal-btn-text {
            font-size: 14px !important;
          }
        }
      }

      &_offert {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.1px;
        margin: 24px 0 0 0;

        & > span {
          color: var(--1, #bf3535);
        }
      }
    }
  }

  &_footer {
    text-align: left;
    padding: 18px 16px 16px 16px;
    margin-top: auto;
    pointer-events: none;
    @media (max-height: 723px) {
      padding: 18px 16px 16px 16px;
      margin-top: auto;
      text-align: right;
    }
  }
}

.map-label-mobile {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); /* Для поддержки Safari */
  background: #00000080;
  border-radius: 2px;
  box-shadow: 0px 2px 10px 0px #00000040;
  z-index: 150;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 160px;
  height: 72px;
  color: white !important;
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: space-between;
  &_title {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
  font-size: 10px;
  font-weight: 400;
}
