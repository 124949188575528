.balance-mobile {
    display: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow-y: scroll;
    background-color: #fff;

    &.active {
        display: flex;
        flex-direction: column;
    }

    &_head {
        padding: 16px;
        background: #fff;
        box-shadow: 0px 5px 32px 0px rgba(0, 0, 0, 0.05);
    }

    &_body {
        display: flex;
        flex-direction: column;
        padding: 16px 16px 24px 16px;
        height: calc(100% - 200px);
    }

    .personal-account_balance-list {
        padding-bottom: 8px;
    }

    &_deposit {
        font-size: 10px;
        color: #737d89;
    }

    &_btn {
        text-align: center;
        margin-top: 24px;
        align-items: center;

        img,
        svg {
            width: 16px;
            height: auto;
            margin-left: 8px;
            margin-right: 0;
            transform: rotate(180deg) translateY(1px);
        }
    }

    .personal-account_balance-action {
        margin-top: auto;
        padding-bottom: 0;
    }

    &.negative {
        color: #b80600;
    }

    .personal-account_actions {
        margin-top: auto;
        background-color: #fff;
        box-shadow: 0px -5px 32px 0px rgba(0, 0, 0, 0.05);
        padding: 12px 16px 25px;
        position: absolute;
        top: 70vh;
    }

    .column {
        flex-direction: column;
    }

    .replenish_types {
        display: flex;
        width: 100%;
        margin: -7px;
        flex-direction: column;
        .replenish_type {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;
            height: 52px;
            margin: 7px;
            padding: 15px;
            border-radius: 4px;
            border: 2px solid#F2F3F6;
            cursor: pointer;
            transition: .2s;

            @media (min-width: 1025px) {
                &:hover {
                    border-color: #606569;
                }
            }

            .replenish_icon {
                margin-top: 10px;
                width: 20px;
                height: 20px;
            }

            img {
                max-width: 87px;
                margin-bottom: 10px;
            }

            span {
                color: #222222;
                font-size: 14px;
                font-weight: 600;
            }

            div:nth-child(2) {
                margin-bottom: 10px;
            }

            &.active {
                border-color: #222;
            }
        }
    }
}
