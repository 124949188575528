.bank-card {
  border-radius: 4px;

  background-color: #2d2d2d;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 96px;
  height: 54px;
  padding: 6px 0 8px 0;

  &:not(:last-child) {
    border-right: 2px solid #222;
  }

  &:first-child {
    background-color: #222;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px 3px 8px;
    background-color: rgba(96, 101, 105, 0.5);

    & > h6 {
      color: #fff;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
  }

  &_number {
    margin: 5px 0 0 0;
    padding: 0 10px 0 0;
    color: #fff;
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
  }

  &_favorite-icon {
    & > path {
      fill: #606569;
    }

    &.active {
      & > path {
        fill: #bf3535;
      }
    }
  }
}
