@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@mixin bigFont {
  font-size: 18px;
  font-weight: 600;
}

@mixin lowGrayFont {
  color: rgba(186, 188, 191, 1);
  font-size: 14px;
  font-weight: 500;
}

.personal-account__rating-page__user-info__worked {
  &--sum {
    @include bigFont;
  }

  &--orders {
    @include lowGrayFont;
  }
  
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 0;
    &--sum {
      font-size: 14px;
    }
    &--orders {
      font-size: 14px;
    }
  }
}

.personal-account {
  &__raiting-page {
    border-top: 2px solid #f2f3f6;

    &__calendar-container {
      display: flex;
      flex-direction: column;

      position: relative;

      .rdrCalendarWrapper {
        position: absolute;
        top: calc(100% + 16px);
        right: 0%;
        z-index: 10;
      }

      svg {
        cursor: pointer;
      }

      svg.active {
        path {
          stroke: rgba(34, 34, 34, 1);
          fill: rgba(34, 34, 34, 1);
        }
      }
    }

    &__calendar-container_mobile {
      display: flex;
      flex-direction: column;

      position: relative;

      .rdrCalendarWrapper {
        position: absolute;
        top: -1080%;
        right: -1200%;
        z-index: 10;
      }

      svg {
        cursor: pointer;
      }

      svg.active {
        path {
          stroke: rgba(34, 34, 34, 1);
          fill: rgba(34, 34, 34, 1);
        }
      }
    }

    &__header-wrapper {
      margin-top: 30px;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    &__header {
      font-size: 18px;
      font-weight: 600;
      color: rgba(186, 188, 191, 1);
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    &__raiting-list {
      border-radius: unset;
    }

    &__raiting-item {
      border: none;
      padding: 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;

      &:nth-of-type(-n + 3) {
        background-color: rgba(0, 143, 75, 0.05);
        width: 100%;
        @media (max-width: 767px) {
          background-color: white;
          margin-top: 0px;
        }
      }

      &_row-one {
        width: 100%;
        display: grid;
        grid-template-columns: 31px auto 140px;
        align-items: center;
        border-bottom: 2px solid #f2f3f6;
        padding-bottom: 10px;
      }

      &_row-two {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
      }

      &__raiting {
        min-width: 40px;
        height: 40px;
        border-radius: 50%;

        background-color: rgba(160, 219, 201, 1);
        color: rgba(34, 34, 34, 1);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__avatar {
        margin-left: 20px;
        min-width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 2px solid rgba(231, 231, 232, 1);

        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

        & > img {
          width: 32px;
          height: 32px;
        }
      }

      &__fullname {
        @include flexColumnCenter;

        width: 40%;

        margin-left: 20px;

        &--name {
          @include lowGrayFont;
        }
      }

      &__car {
        @include flexColumnCenter;

        width: 25%;

        color: rgba(186, 188, 191, 1);

        &--number {
          @include lowGrayFont;
        }
        @media (max-width: 767px) {
          display: flex;
          flex-direction: row;
          gap: 10px;
          width: auto;
          font-weight: 500;
          color: black;
          &--number {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }

      &__bonus {
        @include flexColumnCenter;

        width: 10%;

        &--type {
          @include lowGrayFont;
        }
        @media(max-width: 767px) {
          color: #008F4B;
          width: auto;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 10px;
        }
      }

      &__worked {
        @include flexColumnCenter;

        &--orders {
          @include lowGrayFont;

          text-transform: lowercase;
        }
        @media(max-width: 767px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
      @media (max-width: 767px) {
        box-shadow: 0px 0px 20px 0px #0000001a;
        margin-top: 20px;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

.list-group {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.green-wrapper {
  width: 100vw;
  padding: 20px;
  padding-right: 30px;
  transform: translateX(-20px);
  background-color: rgba(0, 143, 75, 0.05);
}