.personal-account-socials {
  display: flex;
  flex-direction: column;
  margin: -7px;

  &_wrapper {
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    margin: 7px;
    width: 300px;
    padding: 25px 20px;
    height: fit-content;

    @media (max-width: 1024px) {
      width: 100%;
    }

    &.tg {
      border-radius: 4px;
      text-decoration: none;
      color: #222;

      p {
        font-weight: 600;
        margin: 20px 0;
      }

      &.connected {
        display: flex;
        align-items: center;

        p {
          margin: 0 0 0 20px;
        }
      }

      &:hover {
        color: #fff;
        background: linear-gradient(45deg, #239fdb 14.65%, #28a8ea 85.35%), #fff;

        svg {
          rect {
            fill: #fff;
          }
          path {
            fill: #24A0DD;
          }
        }

        .personal-account-socials_tg {
          color: #24A0DD;
          background: #fff;

          svg {
            path {
              stroke: #24A0DD;
            }
          }
        }
      }

      .personal-account-socials_tg {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        border-radius: 2px;
        background: linear-gradient(45deg, #239fdb 14.65%, #28a8ea 85.35%);
        box-shadow: 0px 0px 10px 0px rgba(55, 73, 96, 0.25);
        text-transform: uppercase;
    
        svg {
          margin-left: 10px;
        }
      }

      .personal-account-socials_mobIcon {
        display: none;
      }
    }
  }

  &_divider {
    width: 32px;
    height: 4px;
    background-color: #bf3535;
    // display: none;
    margin: 12px 0;
  }

  &_header {
    color: var(--Text, #222);
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    padding: 0 0 15px 0;
    border-bottom: 2px solid #f2f3f6;
    margin: 0 0 25px 0;
  }

  &_name,
  &_fullname {
    color: var(--Text, #222);
    font-family: Inter;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  &_number,
  &_email {
    display: block;
    color: var(--Text, #222);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }

  &_number {
    margin: 20px 0 0 0;
  }

  &_email {
    margin: 10px 0 0 0;
  }

  &_links {
    margin: 25px 0 0 0;
  }

  &_link {
    width: 28px !important;
    height: 28px !important;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #222;

    &:not(:last-child) {
      margin: 0 0 20px 0;
    }

    svg,
    img {
      width: 60%;
      height: auto;
    }
    svg > path {
      fill: #222;
    }

    &:hover {
      color: #222;
    }
  }

  &_worktime {
    color: var(--Grey-1, #606569);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    margin: 0;
    max-width: 197px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .personal-account-socials {
    width: 100%;

    &_wrapper {
      width: 100%;
      height: auto;
      position: relative;

      &.tg {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 16px 20px;

        svg:first-child {
          width: 34px;
          height: 34px;
        }

        p {
          font-size: 14px !important;
          margin: 0 16px;
          line-height: 120%;
        }

        .personal-account-socials_tg {
          display: none;
        }

        .personal-account-socials_mobIcon {
          display: block;

          svg {
            width: 24px;
            height: auto;
          }
        }

        &.connected {
          justify-content: flex-start;
        }
      }
    }

    &_divider {
      display: block;
    }
    &_link {
      &:not(:last-child) {
        margin: 0 0 12px 0;
      }
    }

    &_header {
      font-size: 14px;
      padding: 0;
      border: none;
      margin: 0;
    }

    &_worktime,
    &_name,
    &_fullname,
    &_number,
    &_email {
      font-size: 12px;
    }

    &_worktime {
      margin: 12px 0 0 0;
    }

    &_links {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}
