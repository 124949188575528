@import "./../../../../scss/mix";

.guarantee {
  &_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 300px;
    background-color: white;
    padding-bottom: 120px;
    @include l-sm {
      margin-bottom: 40px;
      padding-bottom: 0px;
      .red-border-text {
        margin-top: 24px;
      }
    }
  }
  &_header {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include l-sm {
      margin-top: 40px;
    }
    p {
      font-size: 50px;
      font-weight: 600;
      margin: 0;
      @include l-md {
        font-size: 32px;
        margin: 0;
      }
    }
  }
}

.job-button {
  width: 273px;
  height: 49px;
  background: transparent;
  border: 1.5px black solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.2s ease;
  &_mobile {
    width: 100%;
    margin-top: 8vh;
    height: 39px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px black solid;
    color: black;
  }
  svg {
    margin-left: 5%;
  }
  &:hover {
    background: black;
    border: 2px white solid;
    color: white;
    path {
      stroke: white;
    }
  }

  @include l-md {
    width: 223px;
    height: 39px;
    font-size: 12px;
  }
}

.tile-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  margin-top: 8vh;
  @include l-xl {
    grid-template-columns: 1fr 1fr;
  }
  @include l-md {
    grid-template-columns: 1fr;
    margin-top: 5vh;
  }
  &_tile {
    width: 100%;
    height: 286px;
    box-shadow: 0px 0px 20px 0px #00000014;
    display: grid;

    padding: 40px 20px 40px 20px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    @include l-md {
      padding: 20px 10px 20px 20px;
      height: fit-content;
      font-size: 12px;
    }
    p {
      font-size: 30px;
      font-weight: 600;
      @include l-md {
        font-size: 24px;
      }
    }
  }
}

.red-border-text {
  border-left: 6px solid #bf3535;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 400;
  margin-top: 8vh;
  @include l-md {
    font-size: 12px;
  }
}
