@import "./../../../scss/mix.scss";

.rdrCalendarWrapper {
  box-shadow: 0px 0px 20px 0px #0000001a;
  display: block;
  font-size: 14px;
  width: 266px;

  @include l-sm {
    width: 100%;
  }

  &.active {
    display: block;
  }
}

.rdrMonthAndYearPickers {
  font-size: 14px;
  font-weight: 500;
}

.rdrDateDisplay {
  display: none;
}

.rdrMonth {
  width: 100%;
}

.rdrWeekDays {
  height: 31px;
  display: flex;
  align-items: center;
  @include l-sm {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(7, 37px);
  }
}
.rdrDays {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include l-sm {
    display: grid;
    grid-template-columns: repeat(7, 37px);
  }
}

.rdrMonthAndYearWrapper {
  padding-top: 0;
  height: 37px;
  box-shadow: 0px 20px 20px 0px rgb(0 0 0 / 8%);
}

.rdrWeekDay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
}

.rdrDay {
  width: 37px;
  height: 37px;
  @include l-sm {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rdrMonth {
  padding: 0;
}

.rdrDefinedRangesWrapper {
  font-size: 14px;
  padding-top: 12px;
  display: block;
  color: #81899380 !important;
  max-width: 184px;
  background: transparent;
  border-right: none;
  &::before {
    padding-left: 20px;
    font-size: 14px;
    content: "Выбрать числа за:";
    margin-bottom: 10px;
    display: block;
  }
  .rdrInputRanges {
    display: none;
  }
  .rdrStaticRanges {
    button:last-child {
      margin-top: 45px;
    }
  }
  .rdrStaticRange {
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0;
    &::before {
      padding-top: 4px;
      padding-left: 19px;
      content: url("./../../../images/calendar/checkbox-empty.svg");
    }
  }
  .rdrStaticRangeLabel {
    background: transparent !important;
    &:hover {
      background: transparent !important;
    }
  }
  .rdrStaticRangeSelected {
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::before {
      content: url("./../../../images/calendar/checkbox-active.svg");
    }
    color: black !important;
  }
}

.rdrDayToday {
  background: #e7e7e8 !important;
  border-radius: 2px !important;
  top: 0;
}

.rdrDayToday .rdrDayNumber span:after {
  content: none;
}

.rdrStartEdge,
.rdrEndEdge,
.rdrSelected {
  height: 100%;
  background: #222222;
  border-radius: 2px !important;
  margin: 0;
  top: 0;
  left: 0 !important;
  right: 0 !important;

  span {
    color: white !important;

    &::after {
      content: none;
    }
  }
}

.rdrDayDisabled {
  top: 0px;
  padding: 0;
  background: transparent;
}
// .rdrSelected {
//   top: 5px;
// }

.rdrInRange {
  background: #f2f3f6 !important;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
  left: 0 !important;
  right: 0 !important;
  top: 0px;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #222;
}

.rdrDayNumber {
  &::after {
    content: none !important;
  }
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  height: 100%;
  top: 0px;
  border-radius: 0px !important;
  margin: 0;
  z-index: 0;
  border: 0;
}
.rdrDayInPreview {
  background-color: #f2f3f6 !important;
  border-radius: 0px !important;
  bottom: 10px;
}

.rdrDayHovered {
  .rdrDayNumber {
    color: black !important;
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    background-color: #f2f3f6 !important;
    color: black !important;
  }
}

.rdrNextPrevButton {
  background: transparent;

  i {
    opacity: 0;
  }

  &.rdrNextButton {
    background-image: url("../../../images/rent/carousel-prev-arrow.svg");
    background-size: 16px 16px;
    background-position: center center;
    transform: rotate(180deg);
  }

  &.rdrPprevButton {
    background-image: url("../../../images/rent/carousel-next-arrow.svg");
    background-size: 16px 16px;
    background-position: center center;
  }
}
