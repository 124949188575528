.personal-account_modal {
    .modal-content-block {
        min-height: 600px;
        justify-content: flex-start;

        
    }

    .replenish_types {
        display: flex;
        width: 100%;
        margin: -7px;
        flex-direction: column;
        .replenish_type {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;
            height: 70px;
            margin: 7px;
            padding: 15px;
            border-radius: 4px;
            border: 2px solid#F2F3F6;
            cursor: pointer;
            transition: .2s;

            @media (min-width: 1025px) {
                &:hover {
                    border-color: #606569;
                }
            }

            .replenish_icon {
                margin-top: 10px;
                width: 40px;
                height: 40px;
            }

            img {
                max-width: 87px;
                margin-bottom: 10px;
            }

            span {
                color: #222222;
                font-size: 16px;
                font-weight: 600;
            }

            div:nth-child(2) {
                margin-bottom: 10px;
            }

            &.active {
                border-color: #222;
            }
        }
    }
}