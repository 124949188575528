.personal-account_page {
  padding: 40px 0 0;

  @include l-md {
    padding: 16px 0 0 0;
  }

  &_body {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 80px 0 240px 0;
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      padding: 32px 0 30px 0;
    }
  }

  &-rent {
    &_cars {
      & > .personal-account-rent-car:not(:first-child) {
        margin: 25px 0 0 0;
      }
      & > .personal-account-rent-car_mobile:not(:first-child) {
        margin: 24px 0 0 0;
      }
    }
  }

  &.payment {
    padding: 48px 0 0 0;

    & > .personal-account_mobile-burger {
      bottom: 20px;

      @include l-md {
        bottom: 100px;
      }
    }
    @include l-md {
      padding: 16px 0 80px 0;
    }
  }
}

.personal-account_menu {
  border-right: 2px solid var(--Grey-3, #e7e7e8);
  height: 100%;
  padding: 40px 0 0 0;

  &-links {
    padding: 0;
  }
}

.personal-account_head {
  @include l-md {
    flex-direction: column;
    align-items: flex-start !important;
    padding-bottom: 16px;
    border-bottom: 1px solid #222222;
  }
}

.personal-account-data {
  display: flex;

  @include l-md {
    width: 100%;
    padding: 0;
    margin-bottom: 8px;
  }

  &_image {
    width: 75px;
    height: 75px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 25px 0px #0000001a;
    display: flex;
    align-items: center;
    justify-content: center;

    @include l-md {
      height: 54px;
      width: 54px;
    }
  }

  &_body {
    margin: 0 0 0 30px;

    @include l-md {
      margin: 0 0 0 16px;
    }
    @media (max-width: 767px) {
      margin: 0;
    }
  }

  &_name {
    color: #222;
    font-family: Inter;
    font-size: 30px;

    font-weight: 600;
    line-height: 140%; /* 42px */
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin: 0 0 5px 0;

    @include l-md {
      font-size: 20px;
    }
  }

  &_fullname {
    color: #222;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    margin: 0;

    @include l-md {
      font-size: 16px;
    }
  }
  @media (max-width: 767px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.personal-account_mobile-burger {
  position: fixed;
  left: 16px;
  bottom: 16px;
  border-radius: 8px;
  border: 1px solid var(--BG, #f2f3f6);
  background: var(--default-white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @include l-lg {
    display: flex;
  }

  &.upper {
    bottom: 95px;
  }
}

.personal-account_footer {
  color: var(--Grey-2, #babcbf);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 11.2px */
  padding: 20px 0;
  text-align: center;
}

.mobile-menu-personal-account {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);

  &_container {
    background: var(--default-white, #fff);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.24);
  }

  &_link {
    & > a {
      & > p {
        color: #222222;
      }
    }
  }

  &_header-close {
    color: var(--Text, #222);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    text-transform: uppercase;
    margin: 0;
    transform: translateY(1px);
  }

  &_come-to-menu {
    display: flex;

    & > p {
      margin: 0 0 0 16px;
      color: var(--Grey-2, #babcbf);

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }

  &.upper {
    bottom: 95px;
  }
}

.personal-account_transactions {
  width: 100%;
  padding-top: 20px;
  border-top: 2px solid #f2f3f6;

  &-items {
    display: flex;
    align-items: flex-start;
    margin: -20px;

    @media (max-width: 1024px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &-item {
    margin: 20px;

    &:nth-child(1) {
      width: 400px;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: calc(100% - 440px);

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}

.personal-account_fines {
  width: 100%;
  padding-top: 20px;
  border-top: 2px solid #f2f3f6;
}

.personal-account_subscriptions {
  width: 100%;
  padding-top: 30px;
  border-top: 2px solid #f2f3f6;
}
